<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <ng-container>
    <div class="row">
      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="distance"></app-icon>
        <div>
          <p class="text-900 m-0">
            {{avlSummary?.distance ? (avlSummary.distance | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.km" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.TotalDistance" | translate }}</span>
        </div>
      </div>

      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="speed"></app-icon>
        <div>
          <p class="text-900 m-0">
            {{avlSummary?.averageSpeed ? (avlSummary.averageSpeed | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.km" | translate}}/{{"Shipments.Hour" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.AvgSpeed" | translate }}</span>
        </div>
      </div>

      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="speed"></app-icon>
        <div>
          <p class="text-900 m-0">
            {{avlSummary?.maxSpeed ? (avlSummary.maxSpeed | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.km" | translate}}/{{"Shipments.Hour" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.MaxSpeed" | translate }}</span>
        </div>
      </div>

      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="driving"></app-icon>
        <div>
          <p class="text-900 m-0">
            {{avlSummary?.drivingHours ? (avlSummary.drivingHours | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.Hour" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.DrivingHours" | translate }}</span>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="stop"></app-icon>
        <div>
          <p class="text-900 m-0">
            {{avlSummary?.stopHours ? (avlSummary.stopHours | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.Hour" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.StopHours" | translate }}</span>
        </div>
      </div>

      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="engine-active"></app-icon>
        <div class="">
          <p class="text-900 m-0">
            {{avlSummary?.engineOn ? (avlSummary.engineOn | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.Hour" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.EngineActive" | translate }}</span>
        </div>
      </div>

      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="engine-active"></app-icon>
        <div class="">
          <p class="text-900 m-0">
            {{avlSummary?.engineOff ? ((avlSummary.engineOff/(3400000)) | number:'1.0-2') : '_____'}}
            <span class="text-400 weight-normal small">{{"Shipments.Hour" | translate}}</span>
          </p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.EngineIdle" | translate }}</span>
        </div>
      </div>

      <div class="col-6 mb-2 flex align-center">
        <app-icon class="mr-1" name="brake"></app-icon>
        <div class="">
          <p class="text-900 weight-normal m-0">{{avlSummary?.hardBraking || '_____'}}</p>
          <span class="text-400 small">{{ "Shipments.AvlPerformance.HardBraking" | translate }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</app-state-section>
