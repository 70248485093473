import { Expose, Type } from 'class-transformer';
import { Brand } from './brand';
import { TruckType } from './truck-type';
import { Media } from '../../shared-features/models/media';
import { TruckOwnershipType } from '@features/fleets/enums/truck-ownership-type.enum';
import { AvlDeviceType } from '@features/live-tracking/enums/avl-device-type.enum';
import { TruckOperationStatus } from '@features/fleets/enums/truck-operation-status.enum';
import { Status } from '@features/shipments/enums/status.enum';
import { DriverModel } from './driver.model';
import { Fleet } from './fleet';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { StatusHistory } from './status-history';
import { PermittedAccess } from '@features/fleets/models/permitted-access.model';
import { TruckClassify, TruckVerificationStatus } from '@features/fleets/utils/enums';
import { AVLGroup } from '../../live-tracking/models/avl-group.model';
import { AthrOperationCard } from './athr-operation-card.model';
import { Vendors } from '../enums/vendor.enum';

export class Truck extends DropdownModel {
  @Expose() id: number;
  @Expose() manufactureYear: number;
  @Type(() => TruckType)
  @Expose()
  type: TruckType = new TruckType();
  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();
  @Type(() => Brand)
  @Expose()
  brand: Brand = new Brand();
  @Type(() => DriverModel)
  @Expose()
  driver: DriverModel = new DriverModel();
  @Expose() status: Status = Status.ACTIVE;
  @Expose() createdAt: number;
  @Expose() createdBy: string;
  @Expose() plateNumber: string;
  @Expose() picture: Media = new Media();
  @Expose() sequenceNumber: number;
  @Expose() ownerName: string;
  @Expose() licenseExpiry;
  @Type(() => DriverModel)
  @Expose()
  coDriver: DriverModel = new DriverModel();
  @Expose() weight: number;
  @Expose() volume: number;
  @Expose() operationStatus: TruckOperationStatus;
  @Type(() => StatusHistory)
  @Expose()
  statusHistory: StatusHistory = new StatusHistory();
  @Expose() fileUri: string;
  @Expose() message: string;
  @Expose() permits: PermittedAccess[];
  @Expose() truckWorking: boolean;
  @Expose() avlDeviceId: string;
  @Expose() avlDeviceType: AvlDeviceType;
  @Expose() label: string;
  @Expose() rentalDate: number;
  @Expose() dailyPrice: number;
  @Expose() ownershipType: TruckOwnershipType;
  @Expose() sponsorName: string;
  @Expose() truckVerificationStatus: TruckVerificationStatus;
  @Expose() distancePerShipments: number;
  @Expose() totalDistance: number;
  @Expose() plateNumberDetails: string;
  @Expose() ownerId: number;
  @Expose() isElmVerified: boolean;
  @Expose() isExternal: boolean;
  @Expose() countryId?: number;
  @Expose() temperatureControl: boolean;
  @Expose() classify: TruckClassify;
  @Expose() numOfPallets: number;
  @Expose() weightPercentage: number;
  @Expose() headWeight: number;
  @Expose() operationCard?: AthrOperationCard;
  @Expose() isVerifiedByAthr?: boolean;
  @Expose() externalAvlDeviceId: string;
  @Expose() isTrackedExternally: boolean;
  @Expose() externalTrackingService: Vendors;
  @Expose() withAvl: boolean;
  @Expose() @Type(() => Media) licenseAttachment: Media;
  avlGroup: AVLGroup = new AVLGroup();
  avlMobileNumber: string;

  constructor() {
    super();
  }

  get truckType(): string {
    return this.type?.name;
  }

  get dropDownDisplayName(): string {
    const dash = 'ــــ';
    return (
      (this.brand?.name || dash) +
      ' - ' +
      (this.type?.name || dash) +
      ' - ' +
      (this.plateNumber || dash)
    );
  }
  get dropDownValue(): object {
    return this;
  }
}
