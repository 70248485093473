import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper';
import { Order } from '@features/orders/models/order.model';
import { Pallet } from '@features/orders/models/pallet.model';
import { ApiConstant } from '@shared/constants/api.constant';
import { SortDirection } from '@shared/enums/sort-direction.enum';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderLineStatus } from '../enums/order-line-status.enum';
import { OrderLineItem } from '../models/order-line-item.model';
import { OrdersStatusCount } from '../models/orders-status-count.model';

@Injectable({
  providedIn: 'root',
})
export class OrderAPIService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getOrderList(payload: {
    offset?: number;
    limit?: number;
    fromCustomerId?: number;
    searchTerm?: string;
    status?: OrderLineStatus;
    dateRangeType: DateRangeType;
    startFrom?: string;
    endAt?: string;
    sortDirection?: SortDirection;
    sortField?: any;
    IsForCreation?: boolean;
    customerIds?: number[];
    shipmentId?: number;
    orderType?: string[];
    temperatureControl?: string;
    isRush?: boolean;
    withoutPagination?: boolean;
    isDetailed?: boolean;
  }): Observable<Order[]> {
    const { customerIds, orderType, ...resetOfPayload } = payload;
    // Adding brackets array in the key to make the single value as array
    const params = {
      ...resetOfPayload,
      'customerIds[]': payload.customerIds,
      'orderType[]': payload.orderType,
    };
    return this.baseAPI
      .get(ApiConstant.API_GET_ORDER_LIST, { params: filterNullEntity(params) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(Order, res.data);
        })
      );
  }

  getOrderListById(shipmentId) {
    return this.baseAPI.get(ApiConstant.API_GET_ORDER_LIST + '/' + shipmentId).pipe(
      map((res) => {
        return this.mapper.fromJson(Order, res.data);
      })
    );
  }

  getPalletList(payload: {
    orderId?: number;
    isForCreation?: boolean;
    shipmentId?: number;
    searchTerm?: string;
    orderStatus?: string;
    limit?: number;
    offset?: number;
  }): Observable<Pallet[]> {
    return this.baseAPI
      .get(ApiConstant.API_GET_PALLET_LIST, { params: filterNullEntity(payload) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(Pallet, res.data);
        })
      );
  }

  getOrdersCount(payload?: {
    searchTerm?: string;
    dateRangeType: DateRangeType;
    startFrom?: string;
    endAt?: string;
    IsForCreation?: boolean;
    customerIds?: number[];
    fromCustomerId?: number;
    orderType?: string[];
    temperatureControl?: string;
    isRush?: boolean;
    isDetailed?: boolean;
  }): Observable<OrdersStatusCount> {
    const { customerIds, orderType, ...resetOfPayload } = payload;
    const params = {
      ...resetOfPayload,
      'customerIds[]': payload.customerIds,
      'orderType[]': payload.orderType,
    };

    return this.baseAPI
      .get(ApiConstant.API_GET_ORDERS_COUNT, { params: filterNullEntity(params) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(OrdersStatusCount, res.data);
        })
      );
  }

  getUndetailedOrderInfo(payload: { orderId: number }): Observable<OrderLineItem[]> {
    return this.baseAPI.get(ApiConstant.API_GET_UNDETAILED_ORDER_INFO + '/' + payload.orderId).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  checkIfOrderDetailed(fromCustomerId: number): Observable<boolean> {
    return this.getOrderList({
      limit: 1,
      offset: 0,
      fromCustomerId,
      dateRangeType: DateRangeType.ALL_TIME,
    }).pipe(
      map((orders) => {
        const order = orders[0];
        return order && order.isPalletDetails;
      })
    );
  }

  updateOrdersPriority(payload: {
    orders: { orderId: number; priority: number }[];
  }): Observable<any> {
    return this.baseAPI.put(ApiConstant.API_UPDATE_ORDERS_PRIORITY, payload);
  }
}
