import { Component, Input } from '@angular/core';
import { ColorPalette } from '@shared/enums/color-palette.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-items-card',
  templateUrl: './order-items-card.component.html',
  styleUrls: ['./order-items-card.component.scss'],
})
export class OrderItemsCardComponent {
  @Input() selectedPallet;
  protected readonly ColorPalette = ColorPalette;

  constructor(private translateService: TranslateService) {}
}
