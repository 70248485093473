import { Phrases } from '@shared/model/locales.model';

export default {
  header: {
    userMenu: {
      logout: 'logout',
      myScore: 'My Score',
      changeLanguage: 'Change Language',
    },
  },

  opsScore: {
    myScore: 'My Score',
    selectDate: 'Select Date',
    customers: 'Customers',
    trips: 'Trips',
    internal: 'Internal',
    external: 'External',
    avg: 'Avg',
    incentive: 'Incentive',
    totalMargin: 'Total Margin',
    missingDn: 'Missing DN',
    FleetB2c: 'FleetB2c',
    freelancer: 'Freelancer',
    SAR: 'SAR',
  },
} as Phrases;
