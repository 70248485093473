import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiConstant } from '@shared/constants/api.constant';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { TruckHasShipment } from '@features/fleets/models/truck-has-shipment.model';
import { TruckLocation } from '@features/fleets/models/truck-location.modal';
import { TruckStatusRange } from '@features/fleets/models/truck-status-range.model';
import { IFilterPayload, IPagingPayload, ISortPayload } from '@shared-features/interfaces';
import { RentedTrucks } from '@features/fleets/models/rented-truck-report.model';
import { RentedTruckStatusHistory } from '@features/fleets/models/rented-truck-status-history.model';
import { CountsType, TruckOperationStatus, TruckStatus } from '@features/fleets/enums';
// import { TruckStatus } from '@features/fleets/enums/truck-status.enum';
import { AVLGroup } from '@features/live-tracking/models/avl-group.model';
import { ShipmentStatusCount } from '@features/shipments/models/shipment-status-count';
import { ExportedFile } from '@shared-features/models/exported-file';
import { RentedTrucksDashboard } from '@features/fleets/models/rented-trucks-dashboard.model';
import {
  IRentedPlanningTrucksPayload,
  ITruckAreaPreferencePayload,
} from '@features/fleets/interfaces';

import {
  TruckPath,
  TruckShipmentCounts,
  TripPlanningTrucks,
  Truck,
  TrucksDetails,
  RentedTruckContract,
  ClassifiedTadelanes,
} from '@features/fleets/models';
import { SortField } from '@shared/enums/sort-field.enum';
import { SortDirection } from '@shared/enums/sort-direction.enum';
import { AvlVendorType } from '@features/live-tracking/enums';

@Injectable({
  providedIn: 'root',
})
export class TrucksApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  // fleets
  getAllTrucks(
    fleetId: number,
    offset?: number,
    limit?: number,
    searchTerm?: string,
    truckStatus?: TruckStatus,
    sortField?: SortField,
    sortDirection?: SortDirection
  ): Observable<TrucksDetails> {
    const params = filterNullEntity({
      fleetId,
      offset,
      limit,
      searchTerm,
      isForExport: false,
      truckStatus,
      sortField,
      sortDirection,
    });
    return this.baseAPI.get(ApiConstant.GET_ALL_TRUCKS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(TrucksDetails, res.data);
      })
    );
  }

  exportTrucks(
    fleetId: number,
    offset?: number,
    limit?: number,
    searchTerm?: string,
    truckStatus?: TruckStatus
  ): Observable<ExportedFile> {
    const params = filterNullEntity({
      fleetId,
      offset,
      limit,
      searchTerm,
      isForExport: true,
      truckStatus,
    });
    return this.baseAPI.get(ApiConstant.GET_ALL_TRUCKS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }

  // fleets
  addTruck(truck: Truck, fleetId: number): Observable<Truck> {
    const body = {
      fleetId: fleetId,
      plateNumber: truck.plateNumber,
      pictureId: truck.picture?.id,
      brandId: truck.brand?.id,
      truckTypeId: truck.type?.id,
      manufactureYear: truck.manufactureYear,
      sequenceNumber: truck.sequenceNumber,
      ownerName: truck.ownerName,
      driverId: truck.driver?.id,
      coDriverId: truck.coDriver?.id,
      volume: truck.volume,
      weight: truck.weight,
      permits: truck.permits,
      avlDeviceId: truck.avlDeviceId,
      avlDeviceType: truck.avlDeviceType,
      label: truck.label,
      withAvl: truck.withAvl,
      ownershipType: truck.ownershipType,
      dailyPrice: truck.dailyPrice,
      rentalDate: truck.rentalDate,
      plateNumberDetails: truck.plateNumberDetails,
      ownerId: truck.ownerId,
      phone: truck.avlMobileNumber,
      groupId: truck.avlGroup?.id,
      isExternal: truck.isExternal,
      countryId: truck.countryId,
      temperatureControl: truck.temperatureControl,
      classify: truck.classify,
      numOfPallets: truck.numOfPallets,
      headWeight: truck.headWeight,
      externalAvlDeviceId: truck.externalAvlDeviceId,
      isTrackedExternally: truck.isTrackedExternally,
      externalTrackingService: truck.externalTrackingService,
      licenseId: truck.licenseAttachment?.id,
    };

    if (truck.licenseExpiry) body['licenseExpiry'] = truck.licenseExpiry;

    if (truck.operationStatus) body['operationStatus'] = truck.operationStatus;

    return this.baseAPI.post(ApiConstant.ADD_TRUCK, filterNullEntity(body)).pipe(
      map((res) => {
        return this.mapper.fromJson(Truck, res.data);
      })
    );
  }

  createTruckAreaPreference(payload: ITruckAreaPreferencePayload): Observable<string> {
    return this.baseAPI
      .post(ApiConstant.CREATE_TRUCK_AREA_PREFERENCE, filterNullEntity(payload))
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  updateTruckAreaPreference(payload: ITruckAreaPreferencePayload): Observable<string> {
    return this.baseAPI
      .put(ApiConstant.UPDATE_TRUCK_AREA_PREFERENCE, filterNullEntity(payload))
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  deleteTruckAreaPreference(truckAreaPreferenceId: number): Observable<string> {
    return this.baseAPI.delete(
      `${ApiConstant.DELETE_TRUCK_AREA_PREFERENCE}${truckAreaPreferenceId}`
    );
  }

  getTruckById(truckId: number): Observable<Truck> {
    return this.baseAPI.get(ApiConstant.GET_TRUCK_BY_ID + `${truckId}`).pipe(
      map((res) => {
        return this.mapper.fromJson(Truck, res.data);
      })
    );
  }

  // fleets
  updateTruck(truck: Truck, fleetId: number): Observable<Truck> {
    const body = {
      id: truck.id,
      fleetId: Number(fleetId),
      plateNumber: truck.plateNumber,
      pictureId: truck.picture?.id,
      brandId: truck.brand?.id,
      truckTypeId: truck.type?.id,
      manufactureYear: truck.manufactureYear,
      sequenceNumber: truck.sequenceNumber,
      ownerName: truck.ownerName,
      licenseExpiry: new Date(truck.licenseExpiry),
      driverId: truck.driver?.id,
      coDriverId: truck.coDriver?.id,
      volume: truck.volume,
      weight: truck.weight,
      status: truck?.status,
      permits: truck.permits,
      avlDeviceId: truck.avlDeviceId,
      avlDeviceType: truck.avlDeviceType,
      label: truck.label,
      withAvl: truck.withAvl,
      ownershipType: truck.ownershipType,
      dailyPrice: truck.dailyPrice,
      rentalDate: new Date(truck.rentalDate),
      plateNumberDetails: truck.plateNumberDetails,
      ownerId: truck.ownerId,
      isExternal: truck.isExternal,
      temperatureControl: truck.temperatureControl,
      classify: truck.classify,
      numOfPallets: truck.numOfPallets,
      headWeight: truck.headWeight,
      externalAvlDeviceId: truck.externalAvlDeviceId,
      isTrackedExternally: truck.isTrackedExternally,
      externalTrackingService: truck.externalTrackingService,
      licenseId: truck.licenseAttachment?.id,
    };

    if (truck.operationStatus) body['operationStatus'] = truck.operationStatus;

    return this.baseAPI.put(ApiConstant.UPDATE_TRUCK, filterNullEntity(body)).pipe(
      map((res) => {
        return this.mapper.fromJson(Truck, res.data);
      })
    );
  }

  getTruckByAvlId(AvlDeviceId: string, isTrackedExternally: boolean): Observable<TruckHasShipment> {
    const params = filterNullEntity({
      isExternal: isTrackedExternally,
    });
    return this.baseAPI
      .get(ApiConstant.GET_TRUCK_BY_AVL_ID + AvlDeviceId + '/unique-id', { params })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(TruckHasShipment, res.data);
        })
      );
  }

  getTruckShipments(truckId: number): Observable<ShipmentStatusCount> {
    return this.baseAPI.get(ApiConstant.GET_TRUCK_SHIPMENTS + truckId).pipe(
      map((res) => {
        return this.mapper.fromJson(ShipmentStatusCount, res.data);
      })
    );
  }

  getFleetTrucksPlanning(payload: IRentedPlanningTrucksPayload): Observable<TripPlanningTrucks> {
    const params = filterNullEntity(payload);
    return this.baseAPI.get(ApiConstant.GET_FLEET_TRUCKS_PLANNING, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(TripPlanningTrucks, res.data);
      })
    );
  }

  releaseTruck(id: number) {
    return this.baseAPI.put(ApiConstant.RELEASE_TRUCK + `/${id}`, { id });
  }

  getTruckLocation(truckId: number): Observable<TruckLocation> {
    const params = filterNullEntity({ truckId });
    return this.baseAPI.get(ApiConstant.GET_TRUCK_LOCATION, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(TruckLocation, res.data);
      })
    );
  }

  getTruckStatusPerMonth(
    truckId: number,
    month: number,
    year: number
  ): Observable<TruckStatusRange[]> {
    return this.baseAPI
      .get(`${ApiConstant.TRUCK_STATUS_CALENDAR}/${truckId}/${month}/${year}`)
      .pipe(
        map((res) => {
          return this.mapper.fromJson(TruckStatusRange, res.data);
        })
      );
  }

  changeTruckStatus(truckId, operationStatus, ranges, note: string): Observable<any> {
    const body = filterNullEntity({ id: truckId, operationStatus, note, ranges });
    return this.baseAPI.post(ApiConstant.TRUCK_STATUS_CALENDAR, body).pipe(
      map((res) => {
        return this.mapper.fromJson(TruckStatusRange, res.data);
      })
    );
  }

  getRentedTrucks(
    fleetId: number,
    filters: IPagingPayload & ISortPayload & IFilterPayload
  ): Observable<RentedTrucks> {
    return this.baseAPI
      .get(ApiConstant.TRUCK_OPERATION_STATUS, {
        params: { fleetId, ...filterNullEntity(filters) },
      })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(RentedTrucks, res.data);
        })
      );
  }

  exportTrucksOperationStatus(fleetId: number, filters: IFilterPayload): Observable<ExportedFile> {
    const params = filterNullEntity({
      fleetId,
      ...filters,
      isForExport: true,
    });
    return this.baseAPI.get(ApiConstant.OPERATION_STATUS_EXPORT, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }

  getRentedTruckStatusHistory(
    truckId: number,
    filters: IFilterPayload
  ): Observable<RentedTruckStatusHistory> {
    return this.baseAPI
      .get(`${ApiConstant.TRUCK_OPERATION_STATUS}/${truckId}`, {
        params: { id: truckId, ...filterNullEntity(filters) },
      })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(RentedTruckStatusHistory, res.data);
        })
      );
  }

  exportRentedTruckStatusHistory(
    truckId: number,
    filters: IFilterPayload
  ): Observable<ExportedFile> {
    return this.baseAPI
      .get(`${ApiConstant.TRUCK_OPERATION_STATUS}/${truckId}`, {
        params: { id: truckId, ...filterNullEntity(filters), isForExport: true },
      })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(ExportedFile, res.data);
        })
      );
  }

  getCustomReportPathForTruck(payload: {
    uniqueId: string;
    fromDate: string | Date;
    toDate: string | Date;
  }): Observable<TruckPath> {
    return this.baseAPI
      .get(ApiConstant.GET_TRUCK_CUSTOM_REPORT, {
        params: filterNullEntity<typeof payload>(payload),
      })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(TruckPath, res.data);
        })
      );
  }

  getAVLGroups(): Observable<AVLGroup[]> {
    return this.baseAPI.get(ApiConstant.GET_AVL_GROUPS).pipe(
      map((res) => {
        return this.mapper.fromJson(AVLGroup, res.data);
      })
    );
  }

  getAllRentedTruckContracts(truckId: number): Observable<RentedTruckContract[]> {
    const params = filterNullEntity({ truckId });
    return this.baseAPI.get(ApiConstant.GET_ALL_RENTED_TRUCK_CONTRACTS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(RentedTruckContract, res.data);
      })
    );
  }

  addRentedTruckContract(
    rentedTruckContract: RentedTruckContract,
    truckId: number
  ): Observable<RentedTruckContract> {
    const body = filterNullEntity({
      truckId: truckId,
      startDate: rentedTruckContract.startDate,
      endDate: rentedTruckContract.endDate,
      monthlyCost: rentedTruckContract.monthlyCost,
      dailyCost: rentedTruckContract.dailyCost,
    });
    return this.baseAPI.post(ApiConstant.ADD_RENTED_TRUCK_CONTRACT, body).pipe(
      map((res) => {
        return this.mapper.fromJson(RentedTruckContract, res.data);
      })
    );
  }

  updateRentedTruckContract(
    rentedTruckContract: RentedTruckContract
  ): Observable<RentedTruckContract> {
    const body = filterNullEntity({
      id: rentedTruckContract.id,
      startDate: rentedTruckContract.startDate,
      endDate: rentedTruckContract.endDate,
      monthlyCost: rentedTruckContract.monthlyCost,
      dailyCost: rentedTruckContract.dailyCost,
    });
    return this.baseAPI.post(ApiConstant.UPDATE_RENTED_TRUCK_CONTRACT, body).pipe(
      map((res) => {
        return this.mapper.fromJson(RentedTruckContract, res.data);
      })
    );
  }

  getRentedTruckContractById(id: number): Observable<RentedTruckContract> {
    return this.baseAPI.get(ApiConstant.GET_RENTED_TRUCK_CONTRACT_BY_ID + `${id}`).pipe(
      map((res) => {
        return this.mapper.fromJson(RentedTruckContract, res.data);
      })
    );
  }

  getShipmentCounts(payload: {
    customerId: number;
    id: number;
    countsType: CountsType;
  }): Observable<TruckShipmentCounts> {
    return this.baseAPI
      .get(ApiConstant.GET_SHIPMENT_INFO_COUNTS, { params: filterNullEntity(payload) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(TruckShipmentCounts, res.data);
        })
      );
  }

  exportTruckTemperatureLogPDF(payload: {
    shipmentId: number;
    deviceId: string;
    fromDate: Date;
    toDate: Date;
  }): Observable<ExportedFile> {
    return this.baseAPI
      .get(ApiConstant.GET_TRUCK_TEMPERATURE_LOG_PDF, { params: filterNullEntity(payload) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(ExportedFile, res.data);
        })
      );
  }

  getRentedTruckDashboard(): Observable<RentedTrucksDashboard> {
    return this.baseAPI.post(ApiConstant.GET_RENTED_TRUCK_DASHBOARD, {}).pipe(
      map((res) => {
        return this.mapper.fromJson(RentedTrucksDashboard, res.data);
      })
    );
  }

  getClassifiedTradelanes(
    payload: IPagingPayload & { truckId: number }
  ): Observable<ClassifiedTadelanes> {
    const { truckId, ...params } = payload;
    return this.baseAPI
      .get(ApiConstant.GET_CLASSIFIED_TRADELANES + `/${truckId}`, {
        params: filterNullEntity(params),
      })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(ClassifiedTadelanes, res.data);
        })
      );
  }

  changeTruckOperationStatus(body: {
    id: number;
    operationStatus: TruckOperationStatus;
  }): Observable<string> {
    return this.baseAPI.put(ApiConstant.CHANGE_TRUCK_OPERATION_STATUS, filterNullEntity(body)).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
