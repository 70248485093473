import { ShipmentOrdersRouteStatus } from "@features/shipment-creation-template/enums/shipment-orders-route-status.enum"
import { User } from "@features/users/models/user"
import { Expose } from "class-transformer"

export class ShipmentOrderRoute {
  @Expose()
  id: number

  @Expose()
  distance: string

  @Expose()
  duration: string

  @Expose()
  createdAt: Date

  @Expose()
  createdBy: User

  @Expose()
  updatedAt: Date

  @Expose()
  isLatest: boolean

  @Expose()
  status: ShipmentOrdersRouteStatus
}
