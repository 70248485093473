import { Expose, Type } from 'class-transformer';
import { Media } from '../../shared-features/models/media';
import { Fleet } from './fleet';
import { User } from '../../users/models/user';
import { Truck } from './truck';
import { LicenseType } from '@features/fleets/enums/license-type.enum';
import { Nationality } from '@features/fleets/enums/nationality.enum';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { PermittedAccess } from '@features/fleets/models/permitted-access.model';
import { DriversBlock } from './drivers-block';
import { BankInfo } from './bank-info.model';
import { AthrOperationCard } from './athr-operation-card.model';

export class Driver extends DropdownModel {
  @Expose() id: number;
  @Type(() => User)
  @Expose()
  user: User = new User();
  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();
  @Expose() name: string;
  @Expose() iqamaId: number;
  @Expose() nonResidentId: number;
  @Expose() nationality: Nationality;
  @Expose() licenseType: LicenseType;
  @Type(() => Media)
  @Expose()
  licenseAttachment: Media = new Media();
  @Expose() licenseExpiry;
  @Expose() iqamaIdExpiry;
  @Expose() birthDate: Date;
  @Type(() => Media)
  @Expose()
  iqamaAttachment: Media;
  @Type(() => Truck)
  @Expose()
  truck: Truck = new Truck();
  @Expose() title: string;
  @Expose() email: string;
  @Expose() mobileNumber: string;
  @Expose() code: number;
  @Type(() => Media)
  @Expose()
  logo: Media = new Media();
  @Expose() sendSms: boolean;
  @Expose() createdAt: number;
  @Expose() updatedAt: number;
  @Expose() owner: string;
  @Expose() shipmentsCount: number;
  @Expose() isLead: boolean;
  @Expose() comment: string;
  @Expose() shipmentsCountAcceptedByDriver: number;
  @Expose() permits: PermittedAccess[];

  @Type(() => User)
  @Expose()
  createdBy: User = new User();

  @Expose()
  driver_with_iqama: string;
  @Expose() issueNumber: number;
  @Expose() isVerifiedByElm: boolean;
  @Expose() driversBlock?: DriversBlock[];
  @Expose() bankInfo?: BankInfo;
  @Expose() operationCard?: AthrOperationCard;
  @Expose() isVerifiedByAthr?: boolean;

  constructor() {
    super();
  }

  get fullName(): string {
    let fullName: string;
    if (this.user?.firstName && this.user?.lastName)
      fullName = this.user?.firstName + ' ' + this.user?.lastName;
    else if (this.user?.firstName) fullName = this.user?.firstName;
    else if (this.user?.lastName) fullName = this.user?.lastName;
    return fullName;
  }

  get dropDownDisplayName(): string {
    return this.user?.firstName + ' ' + this.user?.lastName;
  }

  get dropDownValue(): object {
    return this;
  }
}
