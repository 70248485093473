import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RequestLog } from '@shared-features/models/request-log.model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { RequestCreatedByType } from '@features/logs/enums/request-created-by-type.enum';
import { RequestHistoryStatus } from '@features/logs/enums/request-history-status.enum';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-request-history',
  templateUrl: './shipment-request-history.component.html',
  styleUrls: ['./shipment-request-history.component.scss'],
})
export class ShipmentRequestHistoryComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('requestModal') requestModal: ModalComponent;
  @Input() logs: RequestLog[];
  @Input() handleEmptyRequestState: boolean;
  translatedLogList: any[];

  selectedItem: RequestLog & { label: string } = null;
  selectedAction: string = null;
  selectedItemState: SectionStateStatus = SectionStateStatus.Ready;
  selectedItemPayload: string = null;
  phrases$: Observable<any>;
  creatorType = RequestCreatedByType;
  destroy$ = new Subject();
  downloadString: SafeUrl;
  phrasesSubscription: Subscription;

  constructor(private translateService: TranslateService, private domSanitizer: DomSanitizer) {
    this.phrases$ = this.translateService.onLangChange.pipe(
      startWith(true),
      switchMap(() => this.translateService.get(['Enum'])),
      map((phrases) => {
        const { Enum } = phrases;
        return {
          actions: Enum.RequestHistoryAction,
          status: Enum.RequestHistoryStatus,
          creatorTypes: Enum.RequestCreatedByType,
        };
      })
    );
  }

  ngOnInit(): void {
    if (this.logs && this.logs.length) {
      this.setListOfLogs();
    }
  }

  ngOnChanges({ logs }: SimpleChanges): void {
    if (this.phrases$ && logs && logs.currentValue !== logs.previousValue) {
      this.setListOfLogs();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setListOfLogs(): void {
    if (this.phrasesSubscription) {
      this.phrasesSubscription.unsubscribe();
    }

    this.phrasesSubscription = this.phrases$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ actions, status, creatorTypes }) => {
        this.translatedLogList = this.logs
          .sort((a, b) => {
            const aTime = a.createdAt;
            const bTime = b.createdAt;
            if (aTime < bTime) {
              return -1;
            } else if (aTime > bTime) {
              return 1;
            }
            return 0;
          })
          .map((request) => {
            const label = request.toType ? 'RequestLog' : 'RequestLogWithoutTo';
            return {
              ...request,
              danger: request.status === RequestHistoryStatus.Failed,
              label: this.translateService.instant(`shipments.${label}`, {
                action: actions[request.action] || request.action,
                from:
                  creatorTypes[request.creatorType || request.fromType] ||
                  request.creatorType ||
                  request.fromType,
                to: creatorTypes[request.toType] || request.toType,
              }),
            };
          });
      });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  handleClickedAction({ item, action }): void {
    this.selectedItem = item;
    this.selectedAction = action;
    this.selectedItemPayload = this.isJson(this.selectedItem.responseData)
      ? JSON.parse(
          action === 'request'
            ? this.selectedItem.requestBody || '{}'
            : this.selectedItem.responseData || '{}'
        )
      : action === 'request'
      ? typeof this.selectedItem.requestBody === 'string'
        ? JSON.parse(this.selectedItem.requestBody)
        : this.selectedItem.requestBody
      : this.selectedItem.responseData;

    this.downloadString = this.domSanitizer.bypassSecurityTrustUrl(
      `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON?.stringify({
          requestUrl: item.requestUrl,
          createdBy: item.creatorType,
          [action === 'request' ? 'requestBody' : 'responseData']: this.selectedItemPayload,
        })
      )}`
    );

    this.requestModal.showModal();
  }
}
