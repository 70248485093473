export enum RequestHistoryAction {
  CreateShipment = 'CREATE_SHIPMENT',
  CarrierAllocated = 'CARRIER_ALLOCATED',
  DriverAccepted_order = 'DRIVER_ACCEPTED_ORDER',
  DriverPickedUp = 'DRIVER_PICKED_UP_ORDER',
  DriverArrived = 'DRIVER_IS_ABOUT_TO_ARRIVE',
  OrderDelivered = 'ORDER_IS_DELIVERED',
  OrderCancelled = 'ORDER_IS_CANCELLED',
  CarrierWayBillCreated = 'CARRIER_WAYBILL_CREATED',
  OrderIsResumed = 'ORDER_IS_RESUMED',
  OrderIsReturned = 'ORDER_IS_RETURNED',
  CreateTrip = 'CREATE_TRIP',
  CreateOrder = 'CREATE_ORDER',
  Create = 'create',
  Cancel = 'cancel',
  CancelShipment = 'CANCEL_SHIPMENT',
  WebhooksService = 'WebhooksService',
  YuserReminder = 'YUSUR_REMINDER',
  PatientConfirmed = 'PATIENT_CONFIRMED',
  OrderCancelledByConsumer='ORDER_IS_CANCELLED_BY_CONSUMER'
}
