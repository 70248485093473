import { Expose, Type } from 'class-transformer';
import { DeliveryNoteStatus } from '@features/delivery-notes/enums/delivery-note-status.enum';
import { ShipmentAction } from '@features/shipments/enums/shipment-action.enum';
import { InvoiceStatus } from '@features/invoices/enums/invoice-status.enum';
import { Media } from '../../shared-features/models/media';
import { Model } from '../../shared-features/models/model';
import { SubDeliveryNote } from './sub-delivery-note';

export class DeliveryNote extends Model {
  @Expose() id: number;

  @Expose() referenceNumber: number;

  @Expose() customerName: string;

  @Expose() fleetName: string;

  @Expose() transportationCost: number;

  @Expose() margin: number;

  @Expose() createdByName: string;

  @Expose() driverName: string;

  @Expose()
  loadedDate: Date;

  @Expose()
  createdAt?: Date;

  @Expose()
  deliveryNoteId: string;

  @Expose()
  from: string;

  @Expose()
  to: string;

  @Expose()
  truckNo: string;

  @Expose()
  waitingDays: number;

  @Expose()
  waitingCharge: string;

  @Expose()
  multiDestinationCharge: string;

  @Expose()
  customerClearanceFees: string;

  @Expose()
  customerReturnCharge: number;

  @Expose()
  weightingFine: string;

  @Expose()
  grandTotal: string;

  @Expose()
  tripRate: number;

  @Expose()
  customerPrice: number;

  @Expose() totalTripCost: number;

  @Expose()
  totalRate: number;

  @Expose()
  vatPercentage: number;

  @Expose() status: DeliveryNoteStatus;

  @Expose() invoiceStatus: InvoiceStatus;

  @Type(() => Media)
  @Expose()
  attachment: Media = new Media();

  @Type(() => DeliveryNote)
  @Expose()
  deliveryNote: SubDeliveryNote = new SubDeliveryNote();

  @Expose()
  totalWaitingCharge: string;

  @Expose()
  invoiceNumber: string;

  @Expose() action: ShipmentAction;
  @Expose() totalExtraCost: number;
  @Expose() tripCost: number;
  @Expose() fleetPrice: number;
  @Expose() count?: number;
  @Expose() partialInvoiceStatus?: string;
  @Expose() hasTransactionChanges?: boolean;
  @Expose() totalPrice: number;
  @Expose() totalExtraPrice: number;
  @Expose() totalPriceWithExtra: number;

  selected: boolean = false;

  constructor() {
    super();
  }
}
