import { Pipe, PipeTransform } from '@angular/core';

type Point = {
  latitude: string | number;
  longitude: string | number;
};

@Pipe({
  name: 'LatLng',
})
export class LatLngPipe implements PipeTransform {
  transform(value: Point[], ...args: unknown[]): google.maps.LatLngLiteral[] {
    return (value || [])
      .map((item) => ({
        lat: item.latitude ? Number(item.latitude) : null,
        lng: item.longitude ? Number(item.longitude) : null,
      }))
      .filter((item) => item.lat && item.lng);
  }
}
