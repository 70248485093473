<app-state-section
  [state]="state"
  [errorStateLabel]="errorStateLabel"
  [emptyStateMainLabel]="emptyStateMainLabel"
  [emptyStateSubLabel]="emptyStateSubLabel"
  [emptyStateImagePath]="emptyStateImagePath"
  (clear)="clear.emit()"
>
  <ng-content select="[top]"></ng-content>
  <div class="scrollable scrollable--x">
    <ng-content></ng-content>
  </div>

  <!-- pagination -->
  <div class="pagination-container">
    <app-pagination
      [total]="totalCount"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [selectionEnabled]="selectionEnabled"
      (pageSizeChange)="onPageSizeChange($event)"
      (pageIndexChange)="onPageIndexChange($event)"
    ></app-pagination>
  </div>

  <ng-content select="[bottom]"></ng-content>
</app-state-section>
