import { LanguageEnum } from '@shared/enums/language.enum';
import { Alphabet } from '@shared-features/models/alphabet.model';
import { Language } from '@shared-features/models/language.model';

export class SharedConstants {
  public static NAV_BAR_MENU_OPEN = 'navBarOpen';
  public static DEVICE_OS_TYPE_MAC = 'Mac';
  public static INIT_VALUE = 'Init';
  public static NOT_SUPPORTED = 'NOT_SUPPORTED';
  public static LOGIN_APP = 'ADMIN';
  public static RENTAL_PROFIT_FAV = 'RENTAL_PROFIT_FAV';

  // Navigation
  public static NAVIGATION_KEY_DASHBOARD = 'DASHBOARD';
  public static NAVIGATION_KEY_AGGREGATOR = 'AGGREGATOR';
  public static NAVIGATION_KEY_CUSTOMERS = 'CUSTOMERS';
  public static NAVIGATION_KEY_SHIPMENTS = 'SHIPMENTS';
  public static NAVIGATION_KEY_B2C_SHIPMENTS = 'B2C_SHIPMENTS';
  public static NAVIGATION_KEY_MY_SHIPMENTS = 'MY_SHIPMENTS';
  public static NAVIGATION_KEY_TRIP_PLANNING = 'TRIP_PLANNING';
  public static NAVIGATION_KEY_FLEET_PROVIDERS = 'FLEETS';
  public static NAVIGATION_KEY_MADAR_PROVIDERS = 'MADAR-PROVIDERS';
  public static NAVIGATION_KEY_FLEETSB2C = 'FLEETSB2C';
  public static NAVIGATION_KEY_B2C_RULES = 'B2C_RULES';
  public static NAVIGATION_KEY_B2C_CONFIGURATION = 'B2C_CONFIGURATION';
  public static NAVIGATION_KEY_B2C_ZONES = 'B2C_ZONES';
  public static NAVIGATION_KEY_CONTRACTS = 'CONTRACTS';
  public static NAVIGATION_KEY_MANAGE = 'MANAGE';
  public static NAVIGATION_KEY_MANAGE_BROADCAST = 'BROADCAST';
  public static NAVIGATION_KEY_MANAGE_USERS = 'USERS';
  public static NAVIGATION_KEY_MANAGE_LEADS = 'LEADS';
  public static NAVIGATION_KEY_LIVE_TRACKING = 'LIVE-TRACKING';
  public static NAVIGATION_KEY_MANAGE_RECEIVERS = 'RECEIVERS';
  public static NAVIGATION_KEY_MANAGE_DELIVERY_NOTES = 'DELIVERY-NOTES';
  public static NAVIGATION_KEY_MANAGE_RELEASE_NOTES = 'RELEASE-NOTES';

  public static NAVIGATION_KEY_REPORTS = 'REPORTS';
  public static NAVIGATION_KEY_INVOICES = 'INVOICES';
  public static NAVIGATION_KEY_CUSTOMER_INVOICES = 'CUSTOMER_INVOICES';
  public static NAVIGATION_KEY_FLEET_INVOICES = 'FLEET_INVOICES';
  public static NAVIGATION_KEY_TRANSPORTATION_PAYMENT = 'TRANSPORTATION_PAYMENT';
  public static NAVIGATION_KEY_CONSOLIDATION_REPORT = 'CONSOLIDATION';
  public static NAVIGATION_KEY_G2G_REPORT = 'G2G';
  public static NAVIGATION_KEY_DRIVERS_REPORT = 'DRIVERS_REPORT';
  public static NAVIGATION_KEY_TRUCKS_REPORT = 'TRUCKS_REPORT';
  public static NAVIGATION_KEY_WASL_REPORT = 'WASL_REPORT';
  public static NAVIGATION_KEY_MANAGE_ORGANIZATIONS = 'ORGANIZATIONS';
  public static NAVIGATION_KEY_MANAGE_SETTINGS = 'SETTINGS';
  public static NAVIGATION_KEY_LOGS = 'LOGS';
  public static NAVIGATION_KEY_MANAGE_B2C_LOGS = 'B2C_LOGS';
  public static NAVIGATION_KEY_MANAGE_EXCEPTION_LOGS = 'EXCEPTION_LOGS';
  public static NAVIGATION_KEY_PAYMENTS = 'PAYMENTS';
  public static NAVIGATION_KEY_AVL_REPORT = 'AVL_REPORT';
  public static NAVIGATION_KEY_RENTAL_PROFIT = 'RENTAL_PROFIT';
  static NAVIGATION_KEY_TRIP_PERFORMANCE = 'TRIP_PERFORMANCE';
  public static NAVIGATION_KEY_SMART_CENTER = 'SMART_CENTER';
  public static NAVIGATION_KEY_CEMENT_REPORT = 'CEMENT_REPORT';
  public static NAVIGATION_KEY_SHIPMENTS_REPORT = 'SHIPMENTS_REPORT';
  public static NAVIGATION_KEY_SHIPMENT_INVOICES_REPORT = 'SHIPMENT_INVOICES_REPORT';
  public static NAVIGATION_KEY_CONTRACT_REPORT = 'CONTRACT_REPORT';
  public static NAVIGATION_KEY_G2G_INTEGRATION = 'G2G_INTEGRATION';
  public static NAVIGATION_KEY_SALES_ORDERS = 'SALES_ORDERS';
  public static NAVIGATION_KEY_ORDERS = 'ORDERS';
  public static NAVIGATION_KEY_TRUCK_UTILIZATION_REPORT = 'TRUCK_UTILIZATION_REPORT';
  public static NAVIGATION_KEY_ORDER_FOLLOWUP_REPORT = 'ORDER_FOLLOWUP_REPORT';

  // Navigation path
  public static HOME_PATH = 'home';
  public static DASHBOARD_PATH = 'dashboard';
  public static CUSTOMERS_PATH = 'customers';
  public static CUSTOMER_PATH = 'customer';
  public static ADD_CUSTOMER_PATH = 'add-customer';

  public static SHIPMENTS_PATH = 'shipments';
  public static MY_SHIPMENTS_PATH = 'my-shipments';
  public static VIEW_SHIPMENT_PATH = 'view-shipment';
  public static EDIT_SHIPMENT_PATH = 'edit-shipment';
  public static ADD_SHIPMENT_PATH = 'add-shipment';
  public static DELIVERY_NOTES_MANAGEMENT_PATH = 'delivery-notes';

  public static FLEET_PROVIDERS_PATH = 'fleets';
  public static FLEET_PROVIDER_PATH = 'fleet';
  public static FLEET_REQUEST_PATH = 'fleet-request';
  public static FLEET_ADD_BULK_DRIVERS = 'add-bulk-drivers';
  public static FLEET_ADD_BULK_TRUCKS = 'add-bulk-trucks';
  public static FLEET_ADD_BULK_DRIVERS_WITH_TRUCKS = 'add-bulk-drivers-with-trucks';
  // public static ADD_FLEET = 'add-fleet';

  public static MANAGE_PATH = '';
  public static BROADCAST_PATH = 'broadcast';
  public static USERS_PATH = 'users';
  public static USER_PATH = 'user';
  public static ADD_USER_PATH = 'add-user';
  public static ORGANIZATIONS_PATH = 'organizations';
  public static ORGANIZATION_PATH = 'organization';
  public static ADD_ORGANIZATION_PATH = 'add-organization';
  public static VIEW_EDIT_ORGANIZATION = 'organization';
  public static SETTINGS_PATH = 'settings';

  public static LOGIN_PATH = 'login';

  public static RECEIVERS_PATH = 'receivers';
  public static RECEIVER_PATH = 'receiver';
  public static ADD_RECEIVER_PATH = 'add-receiver';
  public static VIEW_EDIT_RECEIVER_PATH = 'receiver';
  public static ADD_DRIVER = 'add-driver';
  public static VIEW_EDIT_DRIVER = 'driver';
  public static ADD_TRUCK = 'add-truck';
  public static VIEW_EDIT_TRUCK = 'truck';

  public static CONTRACTS_PATH = 'contracts';
  public static ADD_CONTRACT_PATH = 'add-contract';
  public static EDIT_CONTRACT_PATH = 'edit-contract';
  public static ADD_TRADE_LANE_PATH = 'add-trade-lane';
  public static EDIT_TRADE_LANE_PATH = 'edit-trade-lane';

  public static REPORTS_PATH = 'reports';
  public static CONSOLIDATION_REPORT_PATH = 'consolidation-report';
  public static DRIVERS_REPORT_PATH = 'drivers-report';
  public static TRUCKS_REPORT_PATH = 'trucks_report';

  public static INVOICES_PATH = 'invoices';
  public static DELIVERY_NOTES_PATH = 'delivery-notes';
  public static CUSTOMERS_PERFORMANCE_PATH = 'customers-performance';
  public static TRANSPORTATION_PAYMENTS_PATH = 'transportation-payments';
  public static PAYMENT_PATH = 'payment';

  public static LEADS_PATH = 'leads';

  public static LIVE_TRACKING_PATH = 'live-tracking';
  public static ACCESS_DENIED = 'access-denied';
  public static LOGS_PATH = '';

  // socket
  public static TRACKING_DRIVER_EVENT = 'TRACKING_DRIVER';
  public static TRACKING_SHIPMENT_EVENT = 'TRACKING_SHIPMENT';

  public static COMMERCIAL_FILE_NAME = 'CR';

  // Validations

  public static UPLOAD_IMAGE_MAX_SIZE_HINT = '5MB';
  public static UPLOAD_IMAGE_MAX_SIZE_KB = 5242880;
  public static UPLOAD_IMAGE_FILE_TYPE = ['image/png', 'image/jpeg', 'image/jpg'];
  public static UPLOAD_BULK_USERS_FILE_TYPE = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.csv',
    'text/csv',
  ];

  public static LANGUAGES_NAMES: Language[] = [
    new Language('', 'العربية', LanguageEnum.Arabic),
    new Language('', 'English', LanguageEnum.English),
  ];

  public static UPLOAD_IMAGE_COUNT_LIMIT = 10;

  public static MEDIA = [
    {
      name: 'facebook',
      link: 'https://www.facebook.com/App.Madar',
    },
    {
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/madar-app',
    },
    {
      name: 'twitter',
      link: 'https://twitter.com/madar_app',
    },
  ];

  public static fileSizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
  public static PAGE_SIZE = 100;

  public static ALL = 'All';
  public static SELECT_ALL = 'Select all';

  public static Alphabet: Alphabet[] = [
    new Alphabet('أ', 'A'),
    new Alphabet('ا', 'A'),
    new Alphabet('ب', 'B'),
    new Alphabet('ح', 'J'),
    new Alphabet('د', 'D'),
    new Alphabet('ر', 'R'),
    new Alphabet('س', 'S'),
    new Alphabet('ص', 'X'),
    new Alphabet('ط', 'T'),
    new Alphabet('ع', 'E'),
    new Alphabet('ق', 'G'),
    new Alphabet('ك', 'K'),
    new Alphabet('ل', 'L'),
    new Alphabet('م', 'Z'),
    new Alphabet('ن', 'N'),
    new Alphabet('هـ', 'H'),
    new Alphabet('و', 'U'),
    new Alphabet('ي', 'V'),
    new Alphabet('ى', 'V'),
  ];
}
