import { Expose } from 'class-transformer';
import { Model } from '@shared-features/models/model';
import { assignableFleet } from './assignable-fleet';

export class FleetConfiguration extends Model {
  @Expose() id : number
  @Expose() allowToSeePrice: boolean;
  @Expose() allowToSelectAllDriversAndTrucks: boolean;
  @Expose() assignableFleets: assignableFleet[];
  @Expose() autoAssignAfterCancellation : boolean
  @Expose() autoAssignAfterReminder : boolean
  @Expose() selfAssigned :boolean

  constructor() {
    super();
  }
}
