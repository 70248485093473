<div class="scrollable scrollable--y">
  <div class="p-6">
    <div class="header">
      <app-row align="center">
        <app-col><h3 class="title">{{ "Reports.ColumnOptions" | translate }}</h3></app-col>
        <app-col cols="auto">
          <button type="button" class="btn--link text-primary" (click)="resetOptions()">{{ "Buttons.Reset" | translate }}</button>
        </app-col>
      </app-row>
    </div>
    <div class="pt-4">
      <div>
        <app-icon name="star" siz="sm" [type]="IconType.MaterialDesign" class="text-accent"></app-icon>
        {{'shared.ColumnOptions.ApplyMyColumnSettings' | translate}}
        <app-icon name="exclamation_mark" [matTooltip]="'shared.ColumnOptions.SettingsHint' | translate" matTooltipPosition="above"></app-icon>
      </div>

      <app-state-section [state]="sectionState">
        <div class="py-3 small text-muted" *ngIf="!preferences?.length">{{ "shared.ColumnOptions.NoCustomAdded" | translate}}</div>
        <app-row *ngFor="let preference of preferences; let i = index;">
          <app-col>
            <mat-radio-group [ngModel]="selectedPreference" [ngModelOptions]="{standalone: true}" (ngModelChange)="setPreference(preference)">
              <mat-radio-button [value]="preference.name">{{preference.name}}</mat-radio-button>
            </mat-radio-group>
          </app-col>

          <app-col cols="auto">
            <button mat-icon-button class="btn--xsmall text-400" type="button" (click)="openEditPreferenceDialog(preference)">
              <app-icon name="edit" type="MaterialDesign"></app-icon>
            </button>

            <button mat-icon-button class="btn--xsmall text-400" type="button" (click)="deletePreference(preference)">
              <app-icon name="remove_circle" type="MaterialDesign"></app-icon>
            </button>
          </app-col>
        </app-row>
      </app-state-section>

      <div>
        <button mat-button class="px-0" (click)="openCreatePreferenceDialog()" [disabled]="preferences?.length >= 6">
          <img src="../../../../../assets/images/arrow_right.svg" alt="" />
          <span class="text-small pl-2 text-primary">{{ "shared.ColumnOptions.CreateCustomColumnSettings" | translate }}</span>
        </button>
      </div>
    </div>
    <app-column-options-field-list [formGroup]="form" [state]="state" [keys]="keys" (stateUpdate)="state = $event"></app-column-options-field-list>

    <app-row class="pt-8" gutter="sm">
      <app-col cols="12">
        <button type="button" mat-raised-button class="d-block w-100" color="primary" (click)="applyChanges()">
          {{ "Buttons.Apply" | translate }}
        </button>
      </app-col>
      <app-col cols="12">
        <button type="button" mat-raised-button class="btn-secondary d-block w-100" (click)="resetOptions(); closeDrawer()">
          {{ "Buttons.Cancel" | translate }}
        </button>
      </app-col>
    </app-row>
  </div>
</div>
