import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnimationConstant } from '@shared/constants/animation.constant';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileInputComponent,
    },
  ],

  animations: [AnimationConstant.FadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  @Input() label: string;
  @Input() multiple = false;
  @Input() accept: string;
  @Input() progress$: Observable<number | number[]>;
  @Input() filePlaceHolder: string;
  @ViewChild('uploaderInput') uploaderInput: ElementRef<HTMLInputElement>;
  @ViewChild('hint') hintElement: HTMLElement;
  isDragging = false;
  destroy$ = new Subject();
  value: File | File[] = [];
  touched = false;
  disabled = false;
  showInput = true;
  hasHint = true;
  progressMap: Record<number, number> = {};
  progressSubscription: Subscription;
  onChange = (files: File | File[]) => null;
  onTouched = () => null;

  get filesList(): File[] {
    if (this.multiple) {
      return (this.value as File[]) || [];
    }
    return this.value ? [this.value as File] : [];
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges({ progress$ }: SimpleChanges) {
    if (progress$?.currentValue) {
      this.handleUploadProgress();
    }
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.hasHint = !!this.hintElement && !!this.hintElement.innerHTML;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.changeDetectorRef.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  writeValue(data: File | File[]): void {
    const value: File | File[] =
      Array.isArray(data) && this.multiple ? data : Array.isArray(data) ? data[0] : data;

    this.showInput = false;
    this.value = value;
    this.changeDetectorRef.markForCheck();
    Promise.resolve().then(() => {
      this.showInput = true;
      this.changeDetectorRef.markForCheck();
    });
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  onInputValueChange(event): void {
    const files = [...event.target.files].filter((file) => this.isFileAccepted(file));
    this.isDragging = false;
    if (this.multiple) {
      const value = (this.value as File[]) || [];
      this.value = [...value, ...files.filter((f) => !this.isFileExist(f))];
    } else if (files[0]) {
      this.value = files[0];
    }
    this.onChange(this.value);
    this.markAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  removeFile(file: File): void {
    if (this.multiple) {
      this.value = (this.value as File[]).filter((f) => f !== file);
    } else {
      this.value = null;
    }
    if (this.uploaderInput?.nativeElement) {
      this.uploaderInput.nativeElement.value = '';
    }
    this.onChange(this.value);
    this.markAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  handleDownloadFile(file: File & { media?: any }): void {
    if (file.media) {
      window.open(file.media.url, '_blank');
    }
  }
  private isFileAccepted(file: File): boolean {
    const acceptedTypes = this.accept || '';
    let fileType = file.type.toLowerCase();
    if (!acceptedTypes) {
      return true;
    }
    if (acceptedTypes.includes('/*')) {
      fileType = file.type.toLowerCase().split('/')[0];
    }
    return acceptedTypes.toLowerCase().includes(fileType.toLowerCase());
  }

  private isFileExist(file: File): boolean {
    if (this.multiple) {
      const value = (this.value as File[]) || [];
      return !!value.find((f) => f.name === file.name && file.size === f.size);
    }
    const currentFile = this.value as File;
    return currentFile && currentFile.name === file.name && currentFile.size === file.size;
  }

  private handleUploadProgress() {
    if (this.progressSubscription?.unsubscribe) {
      this.progressSubscription.unsubscribe();
    }

    if (!this.progress$) {
      return;
    }

    this.progressSubscription = this.progress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((progress) => {
        const values = Array.isArray(progress) ? progress : [progress];
        this.progressMap = values.reduce((acc, value, i) => {
          acc[i] = value;
          return acc;
        }, {});

        this.changeDetectorRef.markForCheck();
      });
  }
}
