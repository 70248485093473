import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
} from '@angular/core';
import { GoogleMap, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { MapOverlayComponent } from '@shared/components/map-overlay/map-overlay.component';
import { clusterTextCalculator } from '@shared/components/map-clusterer/clusterer-calculator';
import { IconType } from '@shared/enums/icon-type.enum';

@Component({
  selector: 'app-map-clusterer',
  template: `
    <div>
      <ng-content></ng-content>

      <ng-container *ngIf="showOverlays" [ngClass]="">
        <app-map-clusterer-overlay
          *ngFor="let position of positions"
          [latitude]="position.latitude"
          [longitude]="position.longitude"
          [count]="position.count"
        >
          <app-marker [size]="size" [color]="color">
            <div
              *ngIf="type !== IconType.Image && type !== 'MaterialDesign'"
              class="circle-layer flex align-center justify-center"
              [ngClass]="{
                'bg-success-lighter': isLayerColor,
                'bg-primary-lighter': !isLayerColor
              }"
            >
              <app-icon
                [name]="icon"
                [type]="type"
                *ngIf="iconType !== 'image'"
                [ngClass]="iconClass"
              ></app-icon>
            </div>

            <app-icon
              [name]="icon"
              type="MaterialDesign"
              *ngIf="iconType !== 'image' && type === 'MaterialDesign'"
              [ngClass]="iconClass"
            ></app-icon>

            <img *ngIf="iconType === 'image'" [src]="icon" />
            <span
              class="badge radius-1 px-3"
              [ngClass]="'badge--' + (badgeGrades[position.grade] || badgeGrades[1])"
              >{{ position.count }}</span
            >
          </app-marker>
        </app-map-clusterer-overlay>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .badge {
        display: flex;
        position: absolute;
        top: -0.3rem;
        right: -0.5rem;
        width: 1.3rem;
        height: 1rem;
        line-height: 1.5rem;
        color: white !important;
        align-content: center;
        background-color: var(--color-warn);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        font-weight: normal;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: var(--color-warn);
        color: var(--color-white);
        font-size: 9px;
        z-index: 999;
      }
      .circle-layer {
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
      }
      .badge--primary {
        background-color: var(--color-primary);
      }

      .badge--warn {
        background-color: var(--color-accent);
      }

      .badge--error {
        background-color: var(--color-warn);
      }
    `,
  ],
})
export class MapClustererComponent
  extends MapMarkerClusterer
  implements OnInit, AfterContentInit, OnChanges, OnDestroy
{
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() icon = 'circle';
  @Input() iconClass = 'text-primary';
  @Input() iconType: 'image' | 'icon' = 'icon';
  @Input() color: 'white' | 'primary' | 'error' | 'success' | 'warn' | 'default' | 'black' =
    'white';
  @Input() type: IconType;
  @Input() isLayerColor = false;
  // @ts-ignore
  // tslint:disable-next-line:variable-name
  @ContentChildren(MapOverlayComponent, { descendants: true }) _markers: QueryList<
    MapMarker | MapOverlayComponent
  >;

  // tslint:disable-next-line:variable-name
  protected _calculator = clusterTextCalculator;
  // tslint:disable-next-line:variable-name
  protected _imagePath = '/assets/images/map/cluster-placeholder';
  protected readonly IconType = IconType;

  showOverlays = false;
  positions: { latitude: number; longitude: number; count?: number; grade?: number }[] = [];

  badgeGrades = {
    1: 'primary',
    2: 'warn',
    3: 'danger',
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private mapsDirective: GoogleMap,
    ngZone: NgZone
  ) {
    super(mapsDirective, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshView();
    this.trackInternalCallsOfClusterer();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    const markersToBeDestroy = this._markers.filter((m) => m instanceof MapOverlayComponent);
    markersToBeDestroy.forEach((m: MapOverlayComponent) => m.destroy());
  }

  private checkForMainOverlayVisibility(): void {
    const clusters = this.getClusters();

    const clustersWithMarkers = (clusters || []).filter(
      (cluster) => cluster.getMarkers()?.length > 1
    );
    this.showOverlays = !!clustersWithMarkers?.length;

    this.positions = clustersWithMarkers.map((cluster) => ({
      latitude: cluster.getCenter().lat(),
      longitude: cluster.getCenter().lng(),
      count: cluster.getMarkers()?.length,
      grade: `${cluster.getMarkers()?.length || ''}`.length,
    }));
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  delay(cb, delay = 500): void {
    setTimeout(cb, delay);
  }

  private trackInternalCallsOfClusterer(): void {
    const instance: any = this.markerClusterer;
    const methods = ['redraw_', 'addMarker', 'removeMarker', 'repaint', 'redraw', 'addMarkers'];

    if (!instance) {
      console.error('tracking internals of clusterer failed');
      return;
    }

    methods.forEach((methodName) => {
      const method = instance[methodName];
      if (method) {
        const bound = method.bind(this.markerClusterer);
        instance[methodName] = (...args) => {
          bound(...args);
          this.refreshView();
        };
      }
    });
  }

  private refreshView(): void {
    this.showOverlays = false;
    this.changeDetector.markForCheck();
    this.delay(() => this.checkForMainOverlayVisibility(), 250);
  }
}
