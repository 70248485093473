import { Phrases } from '@shared/model/locales.model';

export default {
  header: {
    userMenu: {
      logout: 'تسجيل الخروج',
      myScore: 'مجموعي',
      changeLanguage: 'تغيير اللغلة',
    },
  },

  opsScore: {
    myScore: 'مجموعي',
    selectDate: 'اختر تاريخ',
    customers: 'عملاء',
    trips: 'رحلات',
    internal: 'داخلية',
    external: 'خارجية',
    avg: 'متوسط',
    incentive: 'حافز',
    totalMargin: 'هامش الكلي',
    missingDn: 'بوليصة تسليم مفقودة',
    FleetB2c: 'ناقل',
    freelancer: 'عامل حر',
    SAR: 'ر.س.',
  },
} as Phrases;
