import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Confirmation, ConfirmationConfig, ConfirmationType } from '../../model/confirmation';
import { ConfirmationService } from '@shared/services/confirmation.service';
import { BackgroundScrollService } from '@shared/services/background-scroll.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@shared/services/language.service';
import { IconType } from '@shared/enums/icon-type.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  confirmData: ConfirmationConfig = new Confirmation();

  protected readonly IconType = IconType;
  protected readonly ConfirmationType = ConfirmationType;
  private dispose$ = new Subject();
  private dialogRefs: { [id: string]: MatDialogRef<any> } = {};
  form: FormGroup;

  constructor(
    private confirmationService: ConfirmationService,
    private backgroundScrollService: BackgroundScrollService,
    private dialogService: MatDialog,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.dispose$.next(true);
    this.dispose$.complete();
  }

  onClickReject(dialogId) {
    this.confirmationService.onConfirmMessageAction(false, false);
    this.dialogRefs[dialogId]?.close();
    this.backgroundScrollService.setScrollState(true);
    this.changeDetectorRef.markForCheck();
  }

  onClickConfirm(dialogId, checkboxValue) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    const textAreaValueToSend = this.form.value.textarea;
    this.confirmationService.setTextareaValue(textAreaValueToSend);
    this.confirmationService.onConfirmMessageAction(true, checkboxValue);
    this.dialogRefs[dialogId]?.close();
    this.backgroundScrollService.setScrollState(true);
    this.form?.get('textarea')?.setValue(null);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    this.confirmationService.confirmationMessage$
      .pipe(
        takeUntil(this.dispose$),
        filter((confirmation) => confirmation !== null)
      )
      .subscribe((confirm) => {
        const refUniqueId = Date.now().toString(32) + Math.random().toString(32).substr(2);
        this.confirmData = confirm;
        this.backgroundScrollService.setScrollState(false);

        confirm.mainActionText = this.translate('shared.confirmation.confirm');
        confirm.cancelActionText = this.translate('shared.confirmation.discard');

        this.dialogRefs[refUniqueId] = this.dialogService.open(this.dialogTemplate, {
          data: {
            ConfirmationType,
            ...confirm,
            reject: () => this.onClickReject(refUniqueId),
            confirm: (checkboxValue) => this.onClickConfirm(refUniqueId, checkboxValue),
          },
        });
        this.initForm();
      });
  }
  private initForm(): void {
    this.form = this.formBuilder.group({});
    if (this.confirmData.textAreaText) {
      this.form.registerControl(
        'textarea',
        new FormControl('', [Validators.required, this.noSpacesValidator])
      );
    }
    if (this.confirmData.checkboxOption) {
      this.form.registerControl('checkboxOption', new FormControl(false));
    }
  }

  private noSpacesValidator(control: FormControl) {
    const hasSpaces = control.value?.trim();
    return hasSpaces === '' ? { noSpaces: true } : null;
  }
}
