// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  base_url: 'https://madarv2-test-app-backend-main-02.azurewebsites.net/',
  socket_url: 'https://madarv2-test-app-backend-micro-mobiletracking.azurewebsites.net/',
  api_version: 'v0.0.1',
  api_prefix: '/api/',
  google_map_key: 'AIzaSyD4cT14FheqMnb4RdZMou4z7SaCZVEv_1k',
  google_analytics: null,
  production: false,
  launchDarklyClient: '63a1b2dce3f97111b75f5a50',
  zenDeskKey: null,
  paytabsKey: 'C6KMNN-KDHV6T-VQN6G7-PQGKTG',
  encryptPasswordKey: 'nwtPzEg:Ev:E_9U#$-AE">=.UVhdkLd`',
  shipmentLogsUrl:
    'https://madarv2-test-app-web-avl-analysis.azurewebsites.net/shipments/shipment-logs',
  exportServiceUrl: 'https://bulk-export.madar.app/v0.0.1/api',
  exportServiceKey: 'hEFN?qd<@CkGv4pt7_8|6/8qxp8bc',
  firebase: {
    apiKey: 'AIzaSyD_IK3d2Dl_7zUwkbWRSGMvtqkUjWVAe7M',
    authDomain: 'madar-c30cd.firebaseapp.com',
    projectId: 'madar-c30cd',
    storageBucket: 'madar-c30cd.appspot.com',
    messagingSenderId: '921570407460',
    appId: '1:921570407460:web:c96d5791bdcd88658314b1',
    measurementId: 'G-M2V33ZS6E6',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
