import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { TradeLane } from './trade-lane';
import { Status } from '@features/shipments/enums/status.enum';
import { Media } from '../../shared-features/models/media';
import { Fleet } from '../../fleets/models/fleet';
import { Customer } from '../../customers/models/customer';
import { User } from '../../users/models/user';
import { FileWithMedia } from '@shared/utils/file-with-media.util';

export class Contract extends Model {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() expiryDate: number;
  @Expose() affectiveDate: number;

  @Type(() => Media)
  @Expose()
  documents: FileWithMedia[] | number[];

  @Type(() => TradeLane)
  @Expose()
  tradeLanes: TradeLane[];

  @Expose() status: Status;

  @Expose() type: string;
  // ContractType;

  @Type(() => Fleet)
  @Expose()
  fleet: Fleet;

  @Type(() => Customer)
  @Expose()
  customer: Customer;

  @Type(() => User)
  @Expose()
  createdBy: User;

  @Expose() createdAt: Date;

  @Type(() => User)
  @Expose()
  updatedBy: User;

  @Expose() updatedAt: Date;

  @Expose() isLastContract: boolean;
  @Expose() parentContract: number;

  @Expose() reference: number;
  @Expose() notes: string;
  @Expose() version: string;
}
