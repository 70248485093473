import { ShipmentRoadFromTraccar } from './../models/shipment-road-from-traccar.model';
import { ShipmentPrices } from './../models/shipment-prices.model';

import { Inject, Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TrailerLabel } from '@features/shipments/enums/trailer-label.enum';
import { DetectionStatus } from '@features/shipments/enums/detection-status.enum';
import { CustomerType } from '@features/customers/enums/customer-type.enum';
import { DriversByDetectionType } from '@features/fleets/enums/drivers-by-detection-type.enum';
import { DriversType } from '@features/fleets/enums/drivers-type.enum';
import { GeneralResponse } from '@core/enums/general-response.enum';
import { Shipment } from '@features/shipments/models/shipment';
import { Contract } from '@features/contracts/models/contract';
import { Tracking } from '@features/live-tracking/models/tracking-driver';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { DriverWithTruck } from '@features/fleets/models/driver-with-truck';
import { IGetAllShipmentsPayload } from '@shared-features/interfaces/shipments-payload.interface';
import { DOCUMENT } from '@angular/common';
import { ApiConstant } from '@shared/constants/api.constant';
import { ShipmentRequest } from '@features/shipments/models/shipment-request.model';
import { AVLSummary } from '@features/live-tracking/models/avl-summary.model';
import { RCustomerLookupEntityType } from '@features/customers/enums/RCustomer-lookup-entity-type.enum';
import { ShipmentDateFilterType } from '@features/filter/enums/shipment-date-filter-type.enum';
import { ShipmentAssignedFleetsType } from '@features/live-tracking/enums/shipment-assigned-fleets-type.enum';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { Status } from '@features/shipments/enums/status.enum';
import { AVLGeofencingUpdates } from '@features/live-tracking/models/avl-geofencing-updates.model';
import { WCCDeliveryNoteLine } from '@features/shipment-creation-template/models/wcc-delivery-note-line';
import { ShipmentsCount } from '@features/shipments/models/shipmentsCount.model';
import { Fleet } from '@features/fleets/models/fleet';
import { Driver } from '@features/fleets/models/driver';
import { ShipmentsModel } from '../models/shipments.model';
import { ShipmentStatusCount } from '../models/shipment-status-count';
import { ShipmentList } from '../models/shipment-list';
import { DoorHistory } from '../models/door-history.model';
import { ExportedFile } from '@shared-features/models/exported-file';
import { AssignFleetPayload } from '@features/shipments/models/assign-fleet-payload.model';
import { TemperatureAndHumidity } from '../models/temperature-and-humidity.model';
import { TransactionHistory } from '../models/transaction-history.model';
import { ShipmentStatus } from '../enums/shipment-status.enum';
import { SamInitialAssignment } from '../models/sam-truck.model';
import { ALL_OPTION_VALUE } from '@shared/components/forms';
import { CompanyLabelType } from '@shared/enums/company-label-type.enum';
import { ShipmentOrderRoute } from '../models/shipment-order-route.model';

@Injectable({
  providedIn: 'root',
})
export class ShipmentsApiService {
  constructor(
    private baseAPI: ApiBaseService,
    private mapper: Mapper,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // overload assignFleet
  assignFleet(payload: AssignFleetPayload): Observable<GeneralResponse> {
    return this.baseAPI
      .post(
        ApiConstant.ASSIGN_FLEET,
        filterNullEntity({
          ...payload,
          assignType: payload.assignType || DriversType.Fleet,
        })
      )
      .pipe(map((res) => res.data));
  }

  assignFreelance(
    shipmentId: number,
    driverId: number,
    assignType: DriversType,
    customerPrice: number,
    fleetPrice: number,
    sendToCustomer?: boolean,
    sendToReceiver?: boolean,
    customerMobileNumber?: string,
    receiverMobileNumber?: string,
    reassignComment?: string
  ): Observable<GeneralResponse> {
    const body = filterNullEntity({
      driverId,
      shipmentId,
      assignType,
      customerPrice,
      fleetPrice,
      sendToCustomer,
      sendToReceiver,
      customerMobileNumber,
      receiverMobileNumber,
      reassignComment,
    });
    return this.baseAPI.post(ApiConstant.ASSIGN_FLEET, body).pipe(map((res) => res.data));
  }

  getAllShipments(
    data: IGetAllShipmentsPayload & { autoCache?: boolean }
  ): Observable<ShipmentsModel> {
    const keys = ['customerId', 'fleetId', 'customerLabel', 'creator', 'assignedBy'];

    keys.forEach((key) => {
      data = Object.assign({}, data, {
        [key]: this.validateArray(data[key], key !== 'customerLabel'),
      });
    });

    const endpoint = data.autoCache
      ? ApiConstant.GET_ALL_SHIPMENTS_CACHE
      : ApiConstant.GET_ALL_SHIPMENTS;

    return this.baseAPI
      .post(endpoint, filterNullEntity(data))
      .pipe(map((res) => this.mapper.fromJson(ShipmentsModel, res.data)));
  }

  getAllShipmentsCount(
    data: IGetAllShipmentsPayload & { autoCache?: boolean }
  ): Observable<ShipmentsCount> {
    const keys = ['customerId', 'fleetId', 'customerLabel', 'creator', 'assignedBy'];

    keys.forEach((key) => {
      data = Object.assign({}, data, {
        [key]: this.validateArray(data[key], key !== 'customerLabel'),
      });
    });

    const endpoint = data.autoCache
      ? ApiConstant.GET_ALL_SHIPMENTS_COUNT_CACHE
      : ApiConstant.GET_ALL_SHIPMENTS_COUNT;

    return this.baseAPI
      .post(endpoint, filterNullEntity(data))
      .pipe(map((res) => this.mapper.fromJson(ShipmentsCount, res.data)));
  }

  getUserPerformance(): Observable<ShipmentStatusCount> {
    return this.baseAPI
      .get(ApiConstant.GET_USER_PERFORMANCE)
      .pipe(map((res) => this.mapper.fromJson(ShipmentStatusCount, res.data)));
  }

  addShipment(
    shipment: Shipment,
    numberOfRepeats: number = 0,
    requests: ShipmentRequest[],
    type: RCustomerLookupEntityType
  ): Observable<Shipment> {
    const body = filterNullEntity({
      customerId: shipment.customer?.id,
      childCustomerId: shipment.childCustomer?.id,
      productTypeId: shipment.productType?.id,
      truckTypeId: shipment.truckType?.id,
      optionalTruckTypeIds: shipment.optionalTruckTypes?.map((i) => (i.id ? i.id : i)),
      receiverId: type === RCustomerLookupEntityType.Receiver ? shipment.receiver?.id : null,
      dropOffCustomerId: type === RCustomerLookupEntityType.Customer ? shipment.receiver?.id : null,
      pickUpLocationId: shipment.pickUpLocation?.id,
      dropOffLocationId: shipment.receiverLocation?.id,
      contactPersonId:
        type === RCustomerLookupEntityType.Receiver ? shipment.contactPerson?.id : null,
      pickUpDate: new Date(new Date(shipment.pickedDateTime)),
      deliveryDate: new Date(new Date(shipment.deliveryDate)),
      repeatedNumber: numberOfRepeats,
      volume: 0,
      name: shipment.name,
      notes: shipment.notes,
      weight: shipment.weight,
      isFreeLanced: shipment.isFreeLanced,
      customerPrice: shipment.customerSpotRate,
      fleetPrice: shipment.fleetSpotRate,
      driverId: shipment.driver?.id,
      priceComment: shipment.priceComment,
      quantity: shipment.quantity,
      weightMeasurementUnit: shipment.weightMeasurementUnit,
      requests: (requests || []).map((request) => ({
        fromType: request.fromType,
        toType: request.toType,
        toLocationId: request.toLocation.id,
        fromLocationId: request.fromLocation.id,
        trailer: request.trailer?.id,
        dropOffUserId: request.dropOffUser?.id,
      })),
      driverAllowance: shipment.driverAllowance,
      totalPrice: shipment.totalPrice,
      truckId: shipment.truck?.id,
      fleetId: shipment.fleet?.id,
      deliveryNoteReference: shipment.deliveryNoteReference,
      creationTemplateType: shipment.shipmentCreationTemplate,
      startShipment: shipment.startShipment,
      comment: shipment.comment,
      forceClose: shipment.forceClose,
      assignAndAutoStart: shipment.assignAndAutoStart,
    });

    return this.baseAPI
      .post(ApiConstant.SHIPMENT_ADD, body)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data) as Shipment));
  }

  updateShipment(
    shipment: Shipment,
    numberOfRepeats: number = 0,
    requests: ShipmentRequest[]
  ): Observable<Shipment> {
    const body = filterNullEntity({
      id: shipment.id,
      name: shipment.name,
      notes: shipment.notes,
      customerId: shipment.customer?.id,
      receiverId: shipment.receiver?.id || null,
      dropOffCustomerId: shipment.dropOffCustomer?.id || null,
      productTypeId: shipment.productType.id,
      truckTypeId: shipment.truckType.id,
      optionalTruckTypeIds: shipment.optionalTruckTypes.map((i) => i.id),
      weight: shipment.weight,
      pickUpLocationId: shipment.pickUpLocation?.id,
      dropOffLocationId: shipment.receiverLocation?.id,
      contactPersonId: shipment.contactPerson?.id,
      pickUpDate: shipment.pickedDateTime ? new Date(shipment.pickedDateTime) : null,
      repeatedNumber: numberOfRepeats,
      quantity: shipment.quantity,
      weightMeasurementUnit: shipment.weightMeasurementUnit,
      requests: (requests || []).map((request) => {
        const item: any = {
          fromType: (request.fromType as any).id,
          toType: (request.toType as any).id,
          toLocationId: request.toLocation.id,
          fromLocationId: request.fromLocation.id,
          trailerId: request.trailer?.id,
          dropOffUserId: request.dropOffUser?.id || null,
        };
        if (request.id) {
          item.id = request.id;
        }
        return item;
      }),
      driverAllowance: shipment.driverAllowance,
    });

    return this.baseAPI
      .put(ApiConstant.SHIPMENT_UPDATE, body)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data) as Shipment));
  }

  updateShipmentPrice(shipment: Shipment): Observable<Shipment> {
    const body = filterNullEntity({
      id: shipment.id,
      customerPrice: shipment.customerSpotRate,
      fleetPrice: shipment.fleetSpotRate,
      priceComment: shipment.priceComment,
    });

    return this.baseAPI
      .put(ApiConstant.SHIPMENT_UPDATE, body)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data) as Shipment));
  }

  getCustomerRate(
    customerId: number,
    truckTypeId: number,
    fromCityId: number,
    toCityId: number,
    productTypeId: number
  ): Observable<Contract> {
    const params = filterNullEntity({
      id: customerId,
      fromCityId,
      toCityId,
      truckTypeId,
      productTypeId,
    });

    return this.baseAPI
      .get(ApiConstant.GET_CUSTOMER_RATE, { params })
      .pipe(map((res) => this.mapper.fromJson(Contract, res.data)));
  }

  getSpotRates(
    id: number,
    fleetId: number,
    truckTypeId: number,
    fromCityId: number,
    toCityId: number,
    productTypeId?: number
  ): Observable<ShipmentPrices> {
    const params = filterNullEntity({
      id,
      fleetId,
      fromCityId,
      toCityId,
      truckTypeId,
      productTypeId,
    });
    return this.baseAPI.get(ApiConstant.API_GET_SPOT_PRICES_RATE, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(ShipmentPrices, res.data);
      })
    );
  }

  updateShipmentStatus(
    shipment: { id: number; status: ShipmentStatus },
    comment?: string
  ): Observable<Shipment> {
    const body = { id: shipment.id, action: shipment.status, comment: comment };
    return this.baseAPI
      .put(ApiConstant.SHIPMENT_UPDATE_STATUS, body)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data)));
  }

  // live tracking
  getShipmentRoad(shipmentId: number): Observable<Tracking[]> {
    return this.baseAPI
      .get(ApiConstant.GET_SHIPMENT_PATH + shipmentId)
      .pipe(map((res) => this.mapper.fromJson(Tracking, res)));
  }

  getShipmentRoadFromTracker(shipmentId: number): Observable<ShipmentRoadFromTraccar> {
    return this.baseAPI
      .get(ApiConstant.GET_SHIPMENT_AVL_PATH + shipmentId)
      .pipe(map((res) => this.mapper.fromJson(ShipmentRoadFromTraccar, res.data)));
  }

  updateShipmentPriceAndDeliveryNote(shipment: Shipment): Observable<Shipment> {
    const body = filterNullEntity({
      id: shipment.id,
      customerPrice: shipment.transactions[0].customerPrice || '0',
      fleetPrice: shipment.transactions[0].fleetPrice || '0',
      deliveryNoteComment: shipment.deliveryNoteComment,
      priceComment: shipment.priceComment,
      deliveryNoteNumber: shipment.deliveryNoteNumber,
      multiDestinationCharge: shipment.multiDestinationCharge,
      returnCharge: shipment.returnCharge,
      detentionCharge: shipment.detentionCharge,
      customerWaitingChargePerDay: shipment.transactions[0].customerWaitingChargePerDay || '0',
      fleetWaitingCharge: shipment.transactions[0].fleetWaitingCharge || '0',
      customerReturnCharge: shipment.transactions[0].customerReturnCharge || '0',
      fleetReturnCharge: shipment.transactions[0].fleetReturnCharge || '0',
      multiDestinationChargeCost: shipment.transactions[0].multiDestinationChargeCost || '0',
      multiDestinationChargePrice: shipment.transactions[0].multiDestinationChargePrice || '0',
      customerClearanceFees: shipment.transactions[0].customerClearanceFees || '0',
      weightingFine: shipment.transactions[0].weightingFine || '0',
      fleetClearanceFees: shipment.transactions[0].fleetClearanceFees || '0',
      fleetWeightingFine: shipment.transactions[0].fleetWeightingFine || '0',
      damageCost: shipment.transactions[0].damageCost || '0',
      advancedPayment: shipment.transactions[0].advancedPayment || '0',
      waitingDays: shipment.transactions[0].waitingDays || '0',
      waitingChargeProfId: shipment.waitingChargeProfId,
    });

    const deliveryNotes = [];
    shipment.deliveryNote.forEach((deliveryNote) => {
      if (deliveryNote.referenceNumber && deliveryNote.attachment?.id) {
        deliveryNotes.push({
          id: deliveryNote.id,
          referenceNumber: deliveryNote.referenceNumber,
          mediaId: deliveryNote.attachment.id,
        });
      } else if (deliveryNote.referenceNumber && !deliveryNote.attachment?.id) {
        deliveryNotes.push({
          id: deliveryNote.id,
          referenceNumber: deliveryNote.referenceNumber,
          mediaId: null,
        });
      }
    });

    (body as any).deliveryNotes = deliveryNotes;

    return this.baseAPI
      .put(ApiConstant.SHIPMENT_UPDATE, body)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data) as Shipment));
  }

  deleteShipment(shipmentId: number): Observable<string> {
    const body = {
      id: shipmentId,
    };
    return this.baseAPI.delete(`${ApiConstant.DELETE_SHIPMENT}${shipmentId}`, body);
  }

  resendOTP(shipmentId: number): Observable<string> {
    const body = { shipmentId };
    return this.baseAPI
      .put(ApiConstant.RESEND_OTP, body)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data)));
  }

  addDriverWithTruck(driverWithTruck: DriverWithTruck): Observable<Driver> {
    const body = {
      addDriverDto: filterNullEntity({
        firstName: driverWithTruck.firstName,
        lastName: driverWithTruck.lastName,
        mobileNumber: driverWithTruck.mobileNumber,
        birthDate: driverWithTruck.birthDate,
        fleetId: driverWithTruck.fleetId,
        nonResidentId: driverWithTruck.nonResidentId,
        iqamaId: driverWithTruck.iqamaId,
        iqamaIdExpiry: driverWithTruck.iqamaIdExpiry,
        nationality: driverWithTruck.nationality,
        owner: driverWithTruck.driverOwner,
        licenseType: driverWithTruck.licenseType,
        licenseExpiry: driverWithTruck.driverLicenseExpiry,
      }),
      addTruckDto: filterNullEntity({
        fleetId: driverWithTruck.fleetId,
        plateNumber: driverWithTruck.plateNumber,
        sequenceNumber: driverWithTruck.sequenceNumber,
        manufactureYear: driverWithTruck.manufactureYear,
        truckTypeId: driverWithTruck.truckTypeId,
        ownerName: driverWithTruck.truckOwnerName,
        brandId: driverWithTruck.brandId,
        weight: driverWithTruck.weight,
        licenseExpiry: driverWithTruck.truckLicenseExpiry,
        ownerId: driverWithTruck.ownerId,
        plateNumberDetails: driverWithTruck.plateNumberDetails,
      }),
    };

    return this.baseAPI
      .post(ApiConstant.ADD_DRIVER_WITH_TRUCK, body)
      .pipe(map((res) => this.mapper.fromJson(Driver, res.data)));
  }

  getAllActiveFleets(): Observable<Fleet[]> {
    return this.baseAPI.get(ApiConstant.GET_ACTIVE_FLEETS).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }

  getShipmentTrackingCoordinatesFromUrl<T = Tracking>(url: string, model?): Observable<T[]> {
    const {
      location: { protocol, hostname, port },
    } = this.document;
    const currentHostUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    const options = { headers: this.baseAPI.getHeaders() };
    options.headers.append('Access-Control-Allow-Origin', currentHostUrl);
    options.headers.append('Access-Control-Allow-Credentials', 'true');
    options.headers.append('Origin', currentHostUrl);

    return this.baseAPI.get(url, options).pipe(
      tap((res) => {
        return res.data;
      }),
      map((response) => {
        return this.mapper.fromJson(model || Tracking, response.coordinates);
      })
    );
  }

  detectDriversLocations(body: {
    latitude: number;
    longitude: number;
    maximumDistanceInMeter: number;
    driverStatus: DetectionStatus;
    customerId?: number;
    fleetId?: number;
    'fleetTypes[]': DriversByDetectionType[];
  }): Observable<Tracking[]> {
    return this.baseAPI
      .get(ApiConstant.DETECT_DRIVERS, { params: filterNullEntity<typeof body>(body) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(Tracking, res);
        })
      );
  }

  markShipmentAsPaidForDriver(body: { shipmentId: number }): Observable<string> {
    return this.baseAPI.put(ApiConstant.MARK_SHIPMENT_AS_PAID_FOR_DRIVER, body).pipe(
      map((res) => {
        return this.mapper.fromJson(String, res);
      })
    );
  }

  downloadEwaybill(shipmentId: number): Observable<ExportedFile> {
    return this.baseAPI.get(ApiConstant.DOWNLOAD_EWAYBILL + '/' + shipmentId).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }

  exportShipments(
    data: IGetAllShipmentsPayload & {
      isForExport?: boolean;
      exportedColumns;
    }
  ): Observable<ExportedFile> {
    const keys = ['customerId', 'fleetId', 'customerLabel', 'creator', 'assignedBy'];

    keys.forEach((key) => {
      data = Object.assign({}, data, {
        [key]: this.validateArray(data[key], key !== 'customerLabel'),
      });
    });

    return this.baseAPI.post(ApiConstant.API_EXPORT_SHIPMENTS, filterNullEntity(data)).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }

  getShipmentRequests(id: string): Observable<ShipmentRequest[]> {
    return this.baseAPI
      .get(ApiConstant.GET_SHIPMENT_REQUESTS + id)
      .pipe(map((res) => this.mapper.fromJson(ShipmentRequest, res.data)));
  }

  getAVLsummary(shipmentId: number): Observable<AVLSummary> {
    return this.baseAPI.get(ApiConstant.GET_SHIPMENT_AVL_SUMMARY + `${shipmentId}`).pipe(
      map((res) => {
        return this.mapper.fromJson(AVLSummary, res.data);
      })
    );
  }

  getShipmentsForRental(fromCityId: number): Observable<ShipmentsModel> {
    const params = filterNullEntity({
      fromCityId,
      shipmentFleetTypeFilter: ShipmentAssignedFleetsType.All,
      listForRental: true,
      status: Status.NEW,
      customerServiceType: CustomerType.Service,
      dateRangeType: DateRangeType.TODAY,
      dateField: ShipmentDateFilterType.pickUpDate,
    });

    return this.baseAPI.get(ApiConstant.GET_ALL_SHIPMENTS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(ShipmentsModel, res.data);
      })
    );
  }

  markShipmentAsReceived(shipmentId: number): any {
    return this.baseAPI.post(ApiConstant.MARK_SHIPMENT_DELIVERED + `${shipmentId}`, {}).pipe(
      map((res) => {
        return this.mapper.fromJson(String, res.data);
      })
    );
  }

  updateTrailerLabel(body: { requestId: number; label: TrailerLabel }): Observable<string> {
    return this.baseAPI.put(ApiConstant.UPDATE_TRAILER_LABEL, body).pipe(
      map((res) => {
        return this.mapper.fromJson(String, res);
      })
    );
  }

  getShipmentGeofencingUpdates(id: number): Observable<AVLGeofencingUpdates[]> {
    return this.baseAPI.get(ApiConstant.GET_GEOFENCING_SHIPMENT_UPDATES + `${id}`).pipe(
      map((res) => {
        return this.mapper.fromJson(AVLGeofencingUpdates, res.data);
      })
    );
  }

  getWCCDeliveryNoteLine(
    shipmentId: number,
    customerId: number
  ): Observable<WCCDeliveryNoteLine[]> {
    const params = filterNullEntity({
      shipmentId,
      customerId,
    });

    return this.baseAPI.get(ApiConstant.WCC_GET_DELIVERY_NOTE_LINE, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(WCCDeliveryNoteLine, res.data);
      })
    );
  }

  private validateArray(value: any[], isNumber: boolean): any[] {
    if (
      !value ||
      (Array.isArray(value) && !value.length) ||
      (typeof value !== 'number' ? value?.includes(ALL_OPTION_VALUE) : false)
    ) {
      return null;
    }
    return (Array.isArray(value) ? value : [value]).map((item) =>
      isNumber ? Number(item) : CompanyLabelType[item]
    );
  }

  getDoorOpenHistory(id: number): Observable<DoorHistory[]> {
    return this.baseAPI.get(ApiConstant.GET_SHIPMENT_DOOR_OPEN_HISTORY + '/' + id).pipe(
      map((res) => {
        return this.mapper.fromJson(DoorHistory, res.data);
      })
    );
  }

  getOrdersCustomers(shipmentId: number): Observable<String[]> {
    return this.baseAPI
      .get(ApiConstant.GET_ORDERS_CUSTOMERS + shipmentId)
      .pipe(map((res) => this.mapper.fromJson(String, res.data)));
  }
  getTemperatureAndHumidity(shipmentId: number): Observable<TemperatureAndHumidity> {
    return this.baseAPI.get(ApiConstant.GET_TEMPERATURE_AND_HUMIDITY + shipmentId).pipe(
      map((res) => {
        return this.mapper.fromJson(TemperatureAndHumidity, res.data);
      })
    );
  }

  getTransactionHistory(body: {
    shipmentId: number;
    type?: string;
  }): Observable<TransactionHistory[]> {
    return this.baseAPI
      .get(ApiConstant.GET_TRANSACTION_HISTORY, { params: filterNullEntity(body) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(TransactionHistory, res.data);
        })
      );
  }

  saveInitialAssign(Payload: { truckId: number; shipmentId: number }): Observable<string> {
    return this.baseAPI.post(ApiConstant.SAVE_INITIAL_ASSIGNMENT, Payload).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  updateExpectedDelivery(body: {
    shipmentId: number;
    expectedDeliveryDate?: Date;
  }): Observable<string> {
    const options = { params: body };
    return this.baseAPI.put(ApiConstant.UPDATE_EXPECTED_DELIVERY_DATE, body, options).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getTruckForSam(body: {
    shipmentId: number;
    offset: number;
    limit: number;
  }): Observable<SamInitialAssignment> {
    const { shipmentId, ...payload } = body;
    return this.baseAPI
      .get(ApiConstant.GET_TRUCK_FOR_SAM + '/' + shipmentId, { params: filterNullEntity(payload) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(SamInitialAssignment, res.data);
        })
      );
  }

  getShipmentOrderRoute(id: number): Observable<ShipmentOrderRoute[]> {
    return this.baseAPI.get(ApiConstant.GET_SHIPMENT_ORDER_ROUTE + `/${id}`).pipe(
      map((res) => {
        return this.mapper.fromJson(ShipmentOrderRoute, res.data);
      })
    );
  }
}
