import { Expose, Type } from 'class-transformer';
import { Media } from '../../shared-features/models/media';
import { Fleet } from './fleet';
import { User } from '../../users/models/user';
import { LicenseType } from '@features/fleets/enums/license-type.enum';
import { Nationality } from '@features/fleets/enums/nationality.enum';
import { DropdownModel } from '../../shared-features/models/dropdown-model';

export class DriverModel extends DropdownModel {
  @Expose() id: number;
  @Type(() => User)
  @Expose()
  user: User = new User();
  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();
  @Expose() name: string;
  @Expose() iqamaId: string;
  @Expose() nationality: Nationality;
  @Expose() licenseType: LicenseType;
  @Type(() => Media)
  @Expose()
  licenseAttachment: Media = new Media();
  @Expose() iqamaAttachmentId: Media = new Media();
  @Expose() licenseAttachmentId: Media = new Media();
  @Expose() title: string;
  @Expose() email: string;
  @Expose() mobileNumber: string;
  @Expose() code: number;
  @Expose() firstName = '';
  @Expose() lastName = '';
  @Type(() => Media)
  @Expose()
  logo: Media = new Media();
  @Expose() createdAt: number;
  @Expose() updatedAt: number;

  constructor() {
    super();
  }

  get fullName(): string {
    return this.user?.firstName + ' ' + this.user?.lastName;
  }

  get dropDownDisplayName(): string {
    return this.user?.firstName + ' ' + this.user?.lastName;
  }

  get dropDownValue(): object {
    return this;
  }
}
