import { Expose, Type } from 'class-transformer';
import { Model } from '@shared-features/models/model';
import { ShipmentHistoryUpdate } from '@features/shipments/models/shipment-history-update.model';
import { User } from '../../users/models/user';
import { ShipmentAction } from '@features/shipments/enums/shipment-action.enum';
import { ShipmentHeadedStatus } from '@shared-features/enums/shipment-headed-status';

export class ShipmentHistoryItem extends Model {
  @Expose()
  id: number;

  @Expose()
  action: string | ShipmentAction;

  @Expose()
  comment: null | string;

  @Expose()
  isLatestAction: boolean;

  @Expose()
  isAutomated: boolean;

  @Expose()
  isAutomatedAVL: boolean;

  @Expose()
  isAutomatedDaleel: boolean;

  @Expose()
  isLatestDriverAssigned: boolean;

  @Expose()
  avlDistance: string;

  @Expose()
  @Type(() => ShipmentHistoryUpdate)
  modifyHistory: ShipmentHistoryUpdate[];

  @Expose()
  modifiedCount: number;

  @Expose()
  coordinates: any;

  @Expose()
  creatorType: string;

  @Expose()
  createdAt: number;

  @Expose()
  @Type(() => User)
  createdBy: User;

  @Expose()
  deletedAt: number;

  @Expose()
  order?: any;
  @Expose()
  providerStatus: string;

  @Expose()
  longitude: string;
  @Expose()
  latitude: string;
  @Expose()
  headedStatus: ShipmentHeadedStatus;
}
