import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NamedField } from '@shared/interfaces/field.interface';
import { FormArray, FormGroup } from '@angular/forms';
import { FieldBase } from './field-base';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-file-field',
  template: `
    <ng-container [formGroup]="group">
      <mat-label class="field__label" *ngIf="field.label"
        >{{ field.label | translate }}
        <span *ngIf="isRequired" class="text-warn">*</span></mat-label
      >
      <div class="field__group">
        <div *ngIf="field.prefix" matPrefix (click)="prefixClick.emit($event)">
          <ng-container #prefixRef></ng-container>
        </div>
        <app-file-input
          class="field__input"
          #element
          [id]="field.name + '_' + uuid"
          [formControl]="group.get(field.name)"
          [label]="label"
          [multiple]="multiple"
          [accept]="accept"
          [progress$]="progress$"
          [filePlaceHolder]="filePlaceHolder"
          (click)="fieldClick.emit($event)"
        >
        </app-file-input>
        <div *ngIf="field.suffix" matSuffix (click)="suffixClick.emit($event)">
          <ng-container #suffixRef></ng-container>
        </div>
        <mat-error *ngIf="errors.length && group.get(field.name)?.touched" class="field__error">
          <app-field-errors [errors]="errors"></app-field-errors>
        </mat-error>
      </div>
    </ng-container>
  `,
  host: { class: 'field' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFieldComponent extends FieldBase {
  @Input() field: NamedField;
  @Input() group: FormGroup | FormArray;
  @ViewChild('element') element: ElementRef;

  @Input() label: string;
  @Input() multiple = false;
  @Input() accept: string;
  @Input() progress$: Observable<number | number[]>;
  @Input() filePlaceHolder: string;
}
