<div class="labels-list">
  <div *ngFor="let shipment of shipments" class="labels-list__item mb-3 p-2 radius-2">
    <div class="align-center no-gutters flex">
      <p class="card__content-title-txt">{{ "Shipments.Table.Id" | translate }}:</p>
      <a [routerLink]="routesUtil.ShipmentsViewWithoutRange.url({ id: shipment.id, type: ShipmentsListType.All })" class="col-auto">
        <span class="card__content-txt col-auto mb-0 ml-2">{{ shipment.id }}</span>
      </a>
    </div>
    <div class="shipment-more-details">
      <ul class="timeline">
        <li class="timeline__item pl-0">
          <div class="card__content-title">
            <app-avatar [round]="true" bgColor="var(--color-lightest)" size="24" iconType="svg" icon="from"></app-avatar>
            <span class="card__content-title-txt">{{ "Shipments.ShipFrom" | translate }}</span>
          </div>
          <div class="ml-5">
            <p class="card__content-main-txt">{{ shipment.pickUpLocation?.city.name || "_______" }}</p>
          </div>
        </li>
        <li>
          <div class="card__content-title">
            <app-avatar bgColor="var(--color-lightest)" size="24" iconType="svg" icon="to"></app-avatar>
            <span class="card__content-title-txt">{{ "Shipments.ShipTo" | translate }}</span>
          </div>
          <div class="ml-5">
            <p class="card__content-main-txt">{{shipment.dropOffLocation.city.name}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="flex align-center">
      <p class="card__content-title-txt">{{ "Shipments.Table.TotalDistance" | translate }}:</p>
      <span class="card__content-txt col-auto mb-0 ml-1">
        {{ shipment.totalDistance ? ((+shipment.totalDistance)?.toFixed(2) ) : "0" }} {{"Shipments.km" | translate}}
      </span>
    </div>
  </div>
</div>
