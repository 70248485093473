import { Expose, Type } from 'class-transformer';
import { Media } from '../../shared-features/models/media';
import { InsuranceModel } from './insurance-model';
import { LocationModel } from '../../live-tracking/models/location.model';
import { User } from '../../users/models/user';
import { CustomerFleetType } from '@features/fleets/enums/customer-fleet-type.enum';
import { Status } from '@features/shipments/enums/status.enum';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { Customer } from '../../customers/models/customer';
import { CompanyLabelType } from '@shared/enums/company-label-type.enum';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { FleetConfiguration } from './fleet-configuration-model';
import { assignableFleet } from './assignable-fleet';
import { BankInfo } from './bank-info.model';
import { AthrOperationCard } from './athr-operation-card.model';
import { FleetClassification } from '../enums/fleet-classification.enum';

export class Fleet extends DropdownModel {
  @Expose() id?: number;
  @Expose() code?: number;
  @Expose() name?: string;
  @Expose() email?: string;
  @Expose() mobileNumber?: string;
  @Expose() contactName?: string;
  @Expose() contactFullName?: string;
  @Expose() contactFirstName?: string;
  @Expose() contactLastName?: string;
  @Expose() contactMobileNumber?: string;
  @Expose() vatNumber?: string;
  @Expose() insuranceExpiryDate?: number;
  @Expose() commercialNumber?: number;
  @Expose() primaryTheme?: string;
  @Expose() isDefault?: boolean;
  @Expose() speedLimit?: number;
  @Expose() assignableFleets?: assignableFleet[];

  @Type(() => LocationModel)
  @Expose()
  locations?: LocationModel[] = [];

  @Expose() status?: Status = Status.ACTIVE;
  @Expose() users?: User[] = [];

  @Type(() => InsuranceModel)
  @Expose()
  insuranceCompany?: InsuranceModel = new InsuranceModel();

  @Type(() => InsuranceModel)
  @Expose()
  insuranceCover?: InsuranceModel = new InsuranceModel();

  @Type(() => Media)
  @Expose()
  commercialRegistration?: Media = new Media();

  @Type(() => Media)
  @Expose()
  logo?: Media = new Media();

  @Expose() createdAt?: number;
  @Expose() updatedAt?: number;
  @Expose() usersCount?: number;
  @Expose() shipmentsNumber?: number;
  @Expose() activationDate?: number;

  @Type(() => Customer)
  @Expose()
  customers?: Customer[] = [];

  @Expose() customerCanAssignDriver?: boolean;
  @Expose() type?: CustomerFleetType;
  @Expose() controlCenterMobile?: string;
  @Expose() businessType?: BusinessType;
  @Expose() dayCapacity?: number;
  @Expose() isMultiPickup?: boolean;
  @Expose() isGeofencingEnabled?: boolean;
  @Expose() totalDriversCount?: number;
  @Expose() totalTrucksCount?: number;
  @Expose() moiNumber?: number;
  @Type(() => FleetConfiguration)
  @Expose()
  fleetConfig?: FleetConfiguration = new FleetConfiguration();
  @Expose() labelType?: CompanyLabelType;
  @Expose() dueDays?: number;
  @Expose() bankInfo?: BankInfo;
  @Expose() operationCard?: AthrOperationCard;
  @Expose() isVerifiedByAthr?: boolean;
  @Expose() classification?: FleetClassification;
  @Expose() isPrimary?: boolean;
  @Expose() isDaleelEnabled?: boolean;
  constructor() {
    super();
  }

  get fullName(): string {
    return this.contactFirstName + ' ' + this.contactLastName;
  }

  get dropDownDisplayName(): string {
    return this.name;
  }

  get dropDownValue(): object {
    return this;
  }
}
