import { Lookup } from '../../shared-features/models/Lookup.model';
import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class CustomerEmailSettings extends Model {
  @Expose()
  customerId: number;

  @Expose()
  IsShipmentCreationEmailEnabled: boolean;

  @Expose()
  IsShipmentAssignedEmailEnabled: boolean;

  @Expose()
  IsShipmentStartEmailEnabled: boolean;

  @Expose()
  IsShipmentArrivedPickupLocationEmailEnabled: boolean;

  @Expose()
  IsShipmentDeliveredEmailEnabled: boolean;

  @Expose()
  IsShipmentLoadedEmailEnabled: boolean;

  @Expose()
  IsShipmentArrivedDeliveryLocationEmailEnabled: boolean;

  // Toggle Buttons Value for Detailed Settings
  @Expose()
  IsShipmentCreationBasicEmailEnabled: boolean;

  @Expose()
  IsShipmentAssignedBasicEmailEnabled: boolean;

  @Expose()
  IsShipmentStartBasicEmailEnabled: boolean;

  @Expose()
  IsShipmentArrivedPickupLocationBasicEmailEnabled: boolean;

  @Expose()
  IsShipmentDeliveredBasicEmailEnabled: boolean;

  @Expose()
  IsShipmentLoadedBasicEmailEnabled: boolean;

  @Expose()
  IsShipmentArrivedDeliveryLocationBasicEmailEnabled: boolean;

  @Expose()
  bcc: string[];

  @Expose()
  tempAlertTo: Lookup[];

  @Expose()
  tempAlertBcc: Lookup[];

  @Expose()
  isTempAlertEnabled: boolean;

  @Expose()
  IsShipmentAssignedDriverIqamaAttachmentEmailEnabled: boolean;

  @Expose()
  IsShipmentStartDriverIqamaAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentArrivedPickupLocationDriverIqamaAttachmentEmailEnabled: boolean;

  @Expose()
  IsShipmentLoadedDriverIqamaAttachmentEmailEnabled: boolean;

  @Expose()
  IsShipmentArrivedDeliveryLocationDriverIqamaAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentDeliveredDriverIqamaAttachmentEmailEnabled: boolean;

  @Expose()
  IsShipmentAssignedDriverLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentStartDriverLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentArrivedPickupLocationDriverLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentLoadedDriverLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentArrivedDeliveryLocationDriverLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentDeliveredDriverLicenseAttachmentEmailEnabled: boolean;

  @Expose()
  IsShipmentAssignedTruckLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentStartTruckLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentArrivedPickupLocationTruckLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentLoadedTruckLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentArrivedDeliveryLocationTruckLicenseAttachmentEmailEnabled: boolean;
  @Expose()
  IsShipmentDeliveredTruckLicenseAttachmentEmailEnabled: boolean;
}
